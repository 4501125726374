import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Folder } from "../models/folder";
import { Post } from "../models/post";

export const useMyFolders = (userId: String): Folder[] => {
  const firestore = useFirestore();
  const coll = collection(firestore, "folders");
  const qref = query(
    coll,
    where("userId", "==", userId),
    where("archived", "==", false)
  );

  const { data: posts } = useFirestoreCollectionData(qref, {
    idField: "id",
  });

  return (posts as any) || [];
};
