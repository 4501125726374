import { useMemo } from "react";
import { Column } from "../../../components/general";
import { textHasLink } from "../../../constants/utils";
import { useCollabMessages } from "../../../hooks/useRoomMessages";
import { Collaboration } from "../../../models/collaboration";
import { User } from "../../../models/user";
import AudioFileList from "../room-files/audio-file-list";
import ImageFileList from "../room-files/image-file-list";
import LinkList from "../room-files/link-list";

export default function CollaborationFiles({
  collab,
  otherUser,
}: {
  collab: Collaboration;
  otherUser?: User;
}) {
  let collabId = collab.id as string;
  const existingPosts = useCollabMessages(collabId as any);

  let filePosts = useMemo(() => {
    return existingPosts.filter((post) => post.image || post.video);
  }, [existingPosts]);

  let audioPosts = useMemo(() => {
    return existingPosts.filter((post) => post.audio);
  }, [existingPosts]);

  let linkPosts = useMemo(() => {
    return existingPosts.filter((post) => textHasLink(post.text));
  }, [existingPosts]);

  return (
    <Column
      style={{
        width: "100%",
        flex: 1,
        padding: "1vh 2vw",
      }}
    >
      <ImageFileList
        filePosts={filePosts}
        collabId={collab.id as string}
        roomName={
          otherUser ? `${otherUser?.username ?? ""} Messages` : "Messages"
        }
      />
      <AudioFileList
        audioPosts={[...audioPosts, ...audioPosts]}
        collabId={collab.id as string}
        roomName={
          otherUser ? `${otherUser?.username ?? ""} Messages` : "Messages"
        }
      />
      <LinkList filePosts={linkPosts} />
    </Column>
  );
}
