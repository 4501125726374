import { FaChevronDown, FaFolder } from "react-icons/fa";
import { Row } from "../../components/general";
import { BodyText, BoldText } from "../../components/text";
import { useMe } from "../../hooks/useMe";
import { useMyFolders } from "../../hooks/useFiles";
import { Folder } from "../../models/folder";
import { useNavigate } from "react-router-dom";
import { IoDocuments } from "react-icons/io5";

export default function FoldersList({
  selectedFolder,
  setSelectedFolder,
}: {
  selectedFolder: Folder;
  setSelectedFolder: any;
}) {
  const me = useMe();
  const userId = me && me.id ? me.id : "-1";
  const folders = useMyFolders(userId);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Row
        style={{
          padding: "2vh 2vw",
          alignItems: "center",
        }}
      >
        <BoldText style={{ fontSize: 24 }}>Folders</BoldText>
        {/* <FaChevronDown style={{ marginLeft: 8, fontSize: 18 }} /> */}
      </Row>
      <div
        onClick={() => {
          setSelectedFolder(null);
        }}
        style={
          selectedFolder ? {} : { backgroundColor: "rgba(255, 255, 255, 0.1)" }
        }
      >
        <RecentFiles />
      </div>

      {folders.map((folder: any) => (
        <div
          onClick={() => {
            setSelectedFolder(folder);
          }}
          style={
            selectedFolder && selectedFolder.id == folder.id
              ? {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                }
              : {}
          }
        >
          <FolderItem folder={folder} />
        </div>
      ))}
    </div>
  );
}

function RecentFiles() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",

        padding: "1vh 2vw",
        width: 220,
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IoDocuments style={{ fontSize: 24, opacity: 0.7 }} />
        <div style={{ marginLeft: 24 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <BoldText style={{ marginBottom: 4 }}>{"Recent Files"}</BoldText>
          </div>
        </div>
      </div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
}
function FolderItem({ folder }: { folder: Folder }) {
  const me = useMe();
  let navigate = useNavigate();
  const userId = me && me.id ? me.id : "-1";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",

        padding: "1vh 2vw",
        width: 220,
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FaFolder style={{ fontSize: 24, opacity: 0.7 }} />
        <div style={{ marginLeft: 24 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <BoldText style={{ marginBottom: 4 }}>{folder.name}</BoldText>
          </div>
        </div>
      </div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
}
