import { IoMdClose } from "react-icons/io";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  increment,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import {
  Column,
  FullHeightContainer,
  HundredPercentContainer,
  Row,
} from "../../components/general";
import { BoldText } from "../../components/text";
import { Colors } from "../../constants/colors";
import { useMe } from "../../hooks/useMe";
import { CollabMessage } from "../../models/collabMessage";
import { Room } from "../../models/room";
import { User } from "../../models/user";
import Header from "../../navigation/header";
import RoomEdit from "./chat-overlays/room-edit";
import RoomFiles from "./chat-overlays/room-files";
import ViewUser from "./chat-overlays/view-user";
import RoomChatDisplay from "./chat/room-chat-display";
import RoomChatHeader from "./chat/room-chat-header";
import MessagesList from "./messages-list/messages-list";

export default function RoomDetail() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  let navigate = useNavigate();
  const { roomId } = useParams();
  const me = useMe();

  const userId = me && me.id ? me.id : "-1";
  const [showOverlay, setShowOverlay] = useState<
    "none" | "view-files" | "edit-room" | "view-user"
  >("none");
  const [selectedUser, setSelectedUser] = useState<User>();
  const [members, setMembers] = useState<User[]>([]);
  const [newMessages, setNewMessages] = useState<CollabMessage[]>([]);
  const [room, setRoom] = useState<Room>();

  useEffect(() => {
    fetchRoom();
  }, [roomId]);

  const fetchRoom = async () => {
    console.log("fetchRoom", roomId);
    if (!roomId) return;
    if (roomId == "home") {
      return;
    }
    let id = roomId as any;
    let snapshot = await getDoc(doc(getFirestore(), "rooms", id));
    if (snapshot.exists()) {
      let collab = { ...snapshot.data(), id: id };
      setRoom(collab as any);
      updateReadCounts(collab as any);
    }
  };

  const createRoom = async () => {
    let unreadCounts: any = {};
    unreadCounts[userId] = 0;
    let room: Room = {
      name: `${me.username}'s Room`,
      userIds: [userId],
      initiatorId: userId,
      initiatorName: me.username,
      createdate: new Date(),
      lastupdate: new Date(),
      archived: false,
      subheading: "New Room",
      lastSenderId: userId,
      unreadCounts: unreadCounts,
    };
    let created = await addDoc(collection(getFirestore(), "rooms"), {
      ...room,
    });
    navigate(`/room/${created.id}`);
  };

  const addSystemMessage = async (message: string) => {
    if (!roomId) return;
    let newMessage = {
      collaborationId: roomId,
      text: message,
      createdAt: new Date(),
      system: true,
    };
    let res = await addDoc(
      collection(getFirestore(), "rooms", roomId, "messages"),
      newMessage
    );
    // @ts-ignore
    setNewMessages([...newMessages, { ...newMessage, id: res.id }]);
  };

  const updateReadCounts = async (room: Room) => {
    if (!room) {
      return;
    }
    let unreadCounts = room.unreadCounts;
    if (!unreadCounts) {
      return;
    }
    let unreadCount: any = room.unreadCounts[userId];
    if (!unreadCount) {
      return;
    }
    if (room.lastSenderId != userId) {
      if (unreadCount[userId] > 0) {
        const userRef = doc(getFirestore(), "users", userId);
        if (me.unreadRoomChatCount > unreadCount - 1) {
          await updateDoc(userRef, {
            unreadRoomChatCount: increment(-unreadCount),
          });
        } else {
          updateDoc(userRef, {
            unreadRoomChatCount: 0,
            lastActive: new Date(),
          });
        }
        await updateDoc(doc(getFirestore(), "rooms", roomId as any), {
          unreadCounts: {
            ...unreadCounts,
            [userId]: 0,
          },
        });
      }
    }
  };

  return (
    <FullHeightContainer>
      <HundredPercentContainer
        style={{
          marginTop: 160,
        }}
      >
        <Row>
          {isMobile && roomId ? (
            <div></div>
          ) : (
            <Column
              style={{
                height: "100%",
                position: "sticky",
                top: 160,
                backgroundColor: Colors.Background,
              }}
            >
              <MessagesList />
            </Column>
          )}

          {isMobile && !roomId ? (
            <div></div>
          ) : (
            <Column
              style={{
                flex: 1,
                display: "flex",
                minHeight: "calc(100vh - 160px)",
                borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: Colors.Background,
                  zIndex: 2,
                  position: "sticky",
                  top: 160,
                }}
              >
                {room && roomId && (
                  <RoomChatHeader
                    room={room}
                    roomId={roomId ?? ""}
                    members={members}
                    setMembers={setMembers}
                    setShowOverlay={setShowOverlay}
                  />
                )}
              </div>

              {room && roomId ? (
                <div
                  style={{
                    width: "100%",
                    overflowY: "scroll",
                    backgroundColor: Colors.Background,
                    position: "relative",
                  }}
                >
                  <RoomChatDisplay
                    room={room}
                    roomId={roomId ?? ""}
                    newMessages={newMessages}
                    setNewMessages={setNewMessages}
                    members={members}
                  />
                </div>
              ) : (
                <div
                  style={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      createRoom();
                    }}
                  >
                    <img
                      src={require("../../assets/create-icon.png")}
                      style={{ width: 350 }}
                    />
                  </div>
                  <BoldText
                    onClick={() => {
                      createRoom();
                    }}
                    style={{ marginTop: 50, cursor: "pointer" }}
                  >
                    CREATE A ROOM
                  </BoldText>
                </div>
              )}
            </Column>
          )}
        </Row>
      </HundredPercentContainer>

      <div
        style={{
          top: 0,
          left: 0,
          right: 0,
          height: 160,
          position: "fixed",
          backgroundColor: Colors.Background,
        }}
      >
        <Header />
      </div>

      <Column
        id="slider"
        className={showOverlay != "none" ? "slide-out" : "slide-in"}
        style={{
          zIndex: 3,
          position: "fixed",
          top: 0,
          height: "100vh",
          width: 440,
          alignItems: "flex-end",
        }}
      >
        <Column
          style={{
            height: "100vh",
            width: showOverlay == "edit-room" ? 340 : 440,
            borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
            backgroundColor: Colors.Background,
          }}
        >
          <IoMdClose
            style={{
              color: Colors.Blue,
              fontSize: 34,
              padding: "3vh 2vw 1vh 2vw",

              cursor: "pointer",
            }}
            onClick={() => setShowOverlay("none")}
          />

          {room && showOverlay == "view-user" && (
            <ViewUser user={selectedUser as any} />
          )}
          {room && showOverlay == "view-files" && <RoomFiles room={room} />}
          {room && showOverlay == "edit-room" && (
            <RoomEdit
              room={room}
              selectUser={(user: any) => {
                setSelectedUser(user);
                setShowOverlay("view-user");
              }}
              setRoom={setRoom}
              members={members}
              setMembers={setMembers}
              addSystemMessage={addSystemMessage}
              closeRoom={() => {
                setShowOverlay("none");
                setRoom(null as any);
                setMembers([]);

                navigate("/room/home");
              }}
            />
          )}
        </Column>
      </Column>
    </FullHeightContainer>
  );
}
