import { collection, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Room } from "../models/room";

export const useMyRooms = (userId: string): Room[] => {
  const firestore = useFirestore();
  const coll = collection(firestore, "rooms");
  const qref = query(coll, where("userIds", "array-contains", userId));

  const { data: posts } = useFirestoreCollectionData(qref, {
    idField: "id",
  });

  return (posts as any) || [];
};
