import moment from "moment";
import { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { RoomAvatar } from "../../../components/room-avatar";
import { BodyText, BoldText } from "../../../components/text";
import { Colors } from "../../../constants/colors";
import { useMe } from "../../../hooks/useMe";
import { Room } from "../../../models/room";

export default function RoomItem({ room }: { room: Room }) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const me = useMe();
  let navigate = useNavigate();
  const userId = me && me.id ? me.id : "-1";

  const timeago = useMemo(() => {
    return moment(new Date(room.lastupdate.seconds * 1000)).fromNow();
  }, [room]);

  useEffect(() => {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "1m",
        m: "%dm",
        mm: "%dm",
        h: "%dh",
        hh: "%dh",
        d: "%dd",
        dd: "%dd",
        M: "%d month",
        MM: "%d months",
        y: "%dy",
        yy: "%dy",
      },
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
        padding: "2vh 1vw 2vh 2vw",
        width: isMobile ? "96vw" : 400,
        alignItems: "flex-start",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(`/room/${room.id}`);
      }}
    >
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <RoomAvatar roomName={room.name} imageSize={50} />
        <div style={{ marginLeft: 24 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <BoldText style={{ marginBottom: 4 }}>{room.name}</BoldText>
          </div>

          <div style={{}}>
            <BodyText style={{ opacity: 0.7 }}>{room.subheading}</BodyText>
          </div>
        </div>
      </div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <div style={{ opacity: 0.6, marginRight: 8 }}>
          <BodyText>{timeago}</BodyText>
        </div>
        <div
          style={{
            width: 12,
            height: 12,
            borderRadius: 12 / 2,
            backgroundColor:
              room.lastSenderId != me.id && room.unreadCounts[userId] > 0
                ? Colors.Blue
                : "transparent",
          }}
        ></div>
      </div>
    </div>
  );
}
