import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OutlineButton } from "../../components/buttons";
import EmptyAudioBackground from "../../components/empty-audio-background";
import {
  Column,
  FullHeightContainer,
  HundredPercentContainer,
  Row,
} from "../../components/general";
import { BoldText } from "../../components/text";
import { Colors } from "../../constants/colors";
import { useMe } from "../../hooks/useMe";
import { Post } from "../../models/post";
import Header from "../../navigation/header";
import FoldersList from "./folders-list";
import FilesPaginator from "./files-paginator";
import { useMediaQuery } from "react-responsive";

function FilesList() {
  const [selectedFolder, setSelectedFolder] = useState<any>(null);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <FullHeightContainer>
      <HundredPercentContainer
        style={{
          marginTop: 160,
        }}
      >
        <Row>
          {!isMobile && (
            <Column
              style={{
                height: "100%",
                position: "sticky",
                top: 160,
                backgroundColor: Colors.Background,
              }}
            >
              <FoldersList
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
              />
            </Column>
          )}

          <FilesPaginator
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
          />
        </Row>
      </HundredPercentContainer>

      <div
        style={{
          top: 0,
          left: 0,
          right: 0,
          height: 160,
          position: "fixed",
          backgroundColor: Colors.Background,
        }}
      >
        <Header />
      </div>
    </FullHeightContainer>
  );
}

export default FilesList;
