import styled from "styled-components";
import { Colors } from "../constants/colors";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RowApart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FlexEndRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FullHeightContainer = styled.div`
  height: 100vh;
  maxheight: 100vh;
`;

export const HundredPercentContainer = styled.div`
  height: 100%;
  maxheight: 100%;
`;
