import styled from "styled-components";
import { Colors } from "../constants/colors";

export const BlueH1 = styled.div`
  color: ${Colors.Blue};
  font-family: "Inconsolata", monospace;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
`;

export const WhiteH2 = styled.div`
  color: ${Colors.White};
  font-family: "Inconsolata", monospace;
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
`;

export const BoldText = styled.div`
  color: ${Colors.White};
  font-family: "Inconsolata", monospace;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
`;

export const BodyText = styled.div`
  color: ${Colors.White};
  font-family: "Helvetica", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
`;

export const MonoText = styled.div`
  color: ${Colors.White};
  font-family: "Inconsolata", monospace;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
`;

export const WhiteH3 = styled.div`
  color: ${Colors.White};
  font-family: "Inconsolata", monospace;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

export const ErrorText = styled.div`
  color: ${Colors.ErrorRed};
  font-family: "Helvetica", sans-serif;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;
