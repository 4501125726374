import styled from "styled-components";
import { Colors } from "../constants/colors";
import { AiOutlineArrowRight } from "react-icons/ai";

export const PrimaryButton = styled.div`
  background-color: ${Colors.Blue};
  padding: 16px 24px;
  color: #fff;
  border-radius: 4px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
  font-size: 20px;
  font-weight: 800;
`;

export const WideButton = styled.div`
  background: rgb(216, 134, 255);
  background: linear-gradient(
    90deg,
    rgba(216, 134, 255, 1) 0%,
    rgba(102, 154, 255, 1) 100%
  );
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #000;
  border-radius: 30px;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
  font-size: 20px;
  font-weight: 800;
  width: 472px;
`;

export const GeneralButton = styled.div`
  background-color: ${Colors.White};
  border-color: ${Colors.White};
  border-width: 2px;
  border-style: solid;
  padding: 4px 16px;
  color: #000;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inconsolata", monospace;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.07em;
`;

export const OutlineButton = styled.div`
  background-color: ${Colors.DarkBlack};
  padding: 4px 16px;
  color: #fff;
  border-color: ${Colors.White};
  border-width: 1px;
  border-style: solid;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inconsolata", monospace;
  font-size: 22px;
  font-weight: 600;
`;

export const SecondaryButton = styled.div`
  border-color: ${Colors.Blue};
  border-width: 1;
  padding: 16px 24px;
  color: ${Colors.Blue};
  border-radius: 4px;
  border-style: solid;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
  font-size: 20px;
  font-weight: 800;
`;

export const UnderlinedLinkButton = styled.div`
  color: ${Colors.Blue};
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${Colors.Blue};
  font-family: "Inconsolata", monospace;
`;
