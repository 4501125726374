import { initializeApp } from "firebase/app";
import { FirebaseAppProvider } from "reactfire";
import ReduxProvider from "./navigation/redux";
import Router from "./navigation/router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const firebaseConfig = {
  apiKey: "AIzaSyC0Q994YlSdRQQbcw2daYUlCA8Ae8zf0gQ",
  authDomain: "realm-4805d.firebaseapp.com",
  projectId: "realm-4805d",
  storageBucket: "realm-4805d.appspot.com",
  messagingSenderId: "191792014167",
  appId: "1:191792014167:web:59dfffc7990c390e781412",
  measurementId: "G-76LVVHTS9K",
};

initializeApp(firebaseConfig);

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <ReduxProvider>
        <Router />
        <ToastContainer />
      </ReduxProvider>
    </FirebaseAppProvider>
  );
}

export default App;
