import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { FaDownload } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import EmptyAudioBackground from "../../../components/empty-audio-background";
import { BodyText, BoldText } from "../../../components/text";
import { CollabMessage } from "../../../models/collabMessage";

export default function AudioFileList({
  audioPosts,
  roomId,
  roomName,
  collabId,
}: {
  audioPosts: CollabMessage[];
  roomId?: String;
  roomName: string;
  collabId?: string;
}) {
  let imageWidth = 110;
  const navigate = useNavigate();

  return (
    <div
      style={{
        marginTop: 10,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        padding: "2vh 0 1vh 1vw",
        borderRadius: 8,
        marginLeft: "-1vw",
        width: "calc(440px - 3vw)",
      }}
    >
      <BoldText style={{ marginBottom: 12 }}>Audio</BoldText>
      {audioPosts.length == 0 && (
        <div
          style={{
            opacity: 0.7,
          }}
        >
          <BodyText>No files.</BodyText>
        </div>
      )}
      <div style={{ flexDirection: "row", flexWrap: "wrap", display: "flex" }}>
        {[...audioPosts].map((item) => (
          <div
            style={{
              marginRight: 12,
              marginBottom: 12,
              border: "1px solid rgba(255, 255, 255, 0.3)",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 8,
            }}
            key={item.id}
            onClick={() => {
              if (collabId) {
                navigate(
                  `/chat-files/${collabId}/${item.id}?roomName=${roomName}`
                );
              } else {
                navigate(
                  `/room-files/${roomId}/${item.id}?roomName=${roomName}`
                );
              }
            }}
          >
            {item.audioImage ? (
              <img
                style={{
                  width: imageWidth,
                  height: imageWidth,
                  borderRadius: 8,
                }}
                src={item.audioImage}
              />
            ) : (
              <EmptyAudioBackground size={imageWidth} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export const ListAudioItem = ({
  audioUrl,
  audioTitle,
  username,
  styles,
}: {
  audioUrl: string;
  audioTitle: string;
  username: string;
  styles?: any;
}) => {
  return (
    <div
      style={{
        borderColor: "rgba(255, 255, 255, 0.4)",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 12,
        padding: 6,
        ...styles,
      }}
    >
      <AudioPlayer
        style={{
          backgroundColor: "transparent",
          color: "white",
          fontFamily: "Helvetica",
        }}
        header={
          <div style={{}}>
            <BodyText
              style={{
                fontSize: 18,
              }}
            >
              {`${audioTitle}`}
            </BodyText>
            {username && (
              <BodyText style={{ marginTop: 4 }}>{username}</BodyText>
            )}
          </div>
        }
        showFilledVolume={false}
        customVolumeControls={[]}
        customProgressBarSection={[
          RHAP_UI.MAIN_CONTROLS,
          RHAP_UI.CURRENT_TIME,
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.DURATION,
          RHAP_UI.ADDITIONAL_CONTROLS,
        ]}
        customControlsSection={[]}
        customAdditionalControls={[
          <a style={{ cursor: "pointer", color: "white" }} href={audioUrl}>
            <FaDownload style={{ fontSize: 18 }} />
          </a>,
        ]}
        autoPlay={false}
        src={audioUrl}
        onPlay={(e) => console.log("onPlay")}
        showJumpControls={false}
        showSkipControls={false}
      />
    </div>
  );
};
