import { FaChevronRight } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { AvatarItem } from "../../../components/avatar-item";
import { OutlineButton } from "../../../components/buttons";
import { Column, Row, RowApart } from "../../../components/general";
import { BoldText } from "../../../components/text";
import { Colors } from "../../../constants/colors";
import { useMe } from "../../../hooks/useMe";
import { Collaboration } from "../../../models/collaboration";
import { User } from "../../../models/user";

export default function CollabChatHeader({
  collab,
  collabId,
  otherUser,
  setSelectedUser,
  setShowOverlay,
}: {
  collab: Collaboration;
  collabId: string;
  otherUser: User | null;
  setShowOverlay: any;
  setSelectedUser: any;
}) {
  const me = useMe();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const userId = me && me.id ? me.id : "-1";

  return (
    <div
      style={{
        padding: 20,
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "rgba(255,255,255,0)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <Row style={{ alignItems: "center" }}>
        {!isMobile && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (otherUser) {
                setSelectedUser(otherUser);
                setShowOverlay("view-user");
              }
            }}
          >
            <AvatarItem
              avatar={otherUser?.profilePicture ?? ""}
              index={0}
              imageSize={80}
            />
          </div>
        )}
        <Column style={{ marginLeft: "2vw" }}>
          <BoldText style={{ fontSize: 28, marginBottom: 8 }}>
            {`${otherUser?.username ?? ""}`.toUpperCase()}
          </BoldText>
        </Column>
      </Row>

      <Column
        style={{
          padding: "0.5vh 0vw",
          minWidth: isMobile ? "5vw" : "18vw",
        }}
      >
        {/* <RowApart style={{ width: "100%" }}>
          {room.id != null && (
            <FetchableAvatarList
              userIds={room.userIds}
              roomId={room.id}
              size={35}
              users={members}
              setUsers={setMembers}
            />
          )}

          {!isMobile && (
            <UnderlinedLinkButton
              style={{
                fontFamily: "Helvetica",
                color: Colors.Purple,
                textDecoration: "none",
                fontSize: 12,
                marginLeft: 30,
              }}
              onClick={() => {
                setShowOverlay("edit-room");
              }}
            >
              View All Members
            </UnderlinedLinkButton>
          )}
        </RowApart> */}
        <OutlineButton
          style={{
            backgroundColor: Colors.TransparentWhite1,
            marginTop: "2vh",
            padding: "12px 20px",
            minWidth: "18vw",
          }}
          onClick={() => {
            setShowOverlay("view-files");
          }}
        >
          <RowApart style={{ width: "100%" }}>
            <BoldText> FILES</BoldText>
            <FaChevronRight style={{ marginLeft: 8, fontSize: 18 }} />
          </RowApart>
        </OutlineButton>
      </Column>
    </div>
  );
}
