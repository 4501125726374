import { HashRouter, Route, Routes } from "react-router-dom";
// import Home from "../screens/home/home";
import { useSigninCheck } from "reactfire";
import { useMe } from "../hooks/useMe";
import CollabItemDetail from "../screens/files/detail/collab-item-detail";
import FileDetail from "../screens/files/detail/files-detail";
import MessageItemDetail from "../screens/files/detail/message-item-detail";
import FilesList from "../screens/files/files-list";
import LandingPage from "../screens/login/landing-page";
import Login from "../screens/login/login";
import CollabDetail from "../screens/rooms/collab-detail";
import RoomDetail from "../screens/rooms/rooms-detail";

export default function Router() {
  const { status, data: signInCheckResult } = useSigninCheck();
  const me = useMe();

  if (status === "loading") {
    return (
      <HashRouter>
        <div className="main-container">
          <div className="main-body" style={{ height: "100vh" }}></div>
        </div>
      </HashRouter>
    );
  }

  if (signInCheckResult.signedIn === true) {
    return (
      <HashRouter>
        <div
          style={{
            background: "#221F29",
            minHeight: "100vh",
            maxHeight: "100vh",
          }}
        >
          <Routes>
            <Route path="/apps" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/room/:roomId" element={<RoomDetail />} />
            <Route path="/room" element={<RoomDetail />} />
            <Route path="/collab/:collabId" element={<CollabDetail />} />
            <Route path="/collab" element={<CollabDetail />} />
            <Route path="/files/:fileId" element={<FileDetail />} />
            <Route
              path="/room-files/:roomId/:messageId"
              element={<MessageItemDetail />}
            />
            <Route
              path="/chat-files/:collabId/:messageId"
              element={<CollabItemDetail />}
            />

            <Route path="/files" element={<FilesList />} />

            <Route path="/" element={<RoomDetail />} />
          </Routes>
        </div>
      </HashRouter>
    );
  }
  return (
    <HashRouter>
      <div style={{ background: "#221F29", minHeight: "100vh" }}>
        <Routes>
          <Route path="/apps" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </div>
    </HashRouter>
  );
}
