import { useMemo } from "react";
import { AvatarItem } from "../../../components/avatar-item";
import { Column } from "../../../components/general";
import { BoldText } from "../../../components/text";
import { Colors } from "../../../constants/colors";
import { useMe } from "../../../hooks/useMe";
import { User } from "../../../models/user";

export default function ViewUser({ user }: { user: User }) {
  const me = useMe();

  return (
    <Column
      style={{
        width: "100%",
        flex: 1,
        padding: "1vh 0vw",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AvatarItem
          avatar={user.profilePicture ?? ""}
          index={0}
          imageSize={70}
        />
        <BoldText
          style={{
            color: "#fff",
            fontSize: 22,
            marginTop: 12,
          }}
        >
          {user.username}
        </BoldText>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <XPView large={true} user={user} xpMax={1000} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "90%",
            alignItems: "center",
            paddingLeft: 40,
            paddingRight: 40,
            marginTop: 4,
            marginBottom: 20,
          }}
        >
          <div onClick={() => {}}>
            <BoldText style={{}}>
              {`RANK `}
              <BoldText
                style={{
                  color: Colors.White,
                  fontSize: 24,
                }}
              >{`#${user.rank}`}</BoldText>
            </BoldText>
          </div>
          <BoldText style={{ fontSize: 24, color: Colors.White }}>
            <BoldText style={{}}>
              {`XP `}
              <BoldText
                style={{
                  color: Colors.White,
                  fontSize: 24,
                }}
              >{`${user.xp}`}</BoldText>
            </BoldText>
          </BoldText>
        </div>
      </div>

      {MISSIONS.map((item) => {
        return (
          <MissionsActivityRow
            key={item.id}
            mission={item as any}
            user={user}
          />
        );
      })}
    </Column>
  );
}

const MissionsActivityRow = ({
  mission,
  user,
}: {
  mission: {
    id: string;
    title: string;
    points: number;
    kind: XPKind;
  };
  user: User;
}) => {
  const xpMax = 1000; //= GeneralDataStore.useState((s) => s.xpMax);
  const inviteMax = 100; //= GeneralDataStore.useState((s) => s.inviteMax);
  const postMax = 100; //= GeneralDataStore.useState((s) => s.postMax);
  const submissionMax = 100; //= GeneralDataStore.useState((s) => s.submissionMax);
  const winMax = 100; //= GeneralDataStore.useState((s) => s.winMax);

  const followMax = 200;

  const missionSubText = useMemo(() => {
    if (!user) {
      return false;
    }
    switch (mission.kind) {
      case XPKind.completeProfileShort:
        return (user as any).didFinishProfileLong
          ? "90%"
          : (user as any).didFinishProfileShort
          ? "70%"
          : "10%";

      case XPKind.addDayToPostStreak:
        return `${user.postCount} POSTS`;

      case XPKind.submitChallenge:
        return `${user.submissionCount}`;

      case XPKind.winChallenge:
        return `${user.winCount}`;

      case XPKind.receive25Follows:
        return `${user.followerCount}`;

      case XPKind.inviteUser:
        return `${user.inviteCount ? user.inviteCount : 0}`;
    }
  }, [user, mission]);

  const missionPercentFilled = useMemo(() => {
    if (!user) {
      return 0;
    }
    switch (mission.kind) {
      case XPKind.completeProfileShort:
        return (user as any).didFinishProfileLong
          ? 0.9
          : (user as any).didFinishProfileShort
          ? 0.7
          : 0.1;

      case XPKind.addDayToPostStreak:
        if (user.postCount == 0) {
          return 0;
        }
        let content = user.postCount / postMax;
        if (content < 0.1) {
          return 0.1;
        } else {
          return content;
        }

      case XPKind.submitChallenge:
        if (user.submissionCount == 0) {
          return 0;
        }
        let submissions = user.submissionCount / submissionMax;
        if (submissions < 0.1) {
          return 0.1;
        } else {
          return submissions;
        }

      case XPKind.winChallenge:
        if (user.winCount == 0) {
          return 0;
        }
        let wins = user.winCount / winMax;
        if (wins < 0.1) {
          return 0.1;
        } else {
          return wins;
        }

      case XPKind.receive25Follows:
        if (user.followerCount == 0) {
          return 0;
        }
        let followers = user.followerCount / followMax;
        if (followers < 0.1) {
          return 0.1;
        } else {
          return followers;
        }

      case XPKind.inviteUser:
        if (!user.inviteCount) {
          return 0;
        }
        if (user.inviteCount == 0) {
          return 0;
        }
        let invites = user.inviteCount / inviteMax;
        if (invites < 0.1) {
          return 0.1;
        } else {
          return invites;
        }
    }
    return 0;
  }, [user, mission]);

  return (
    <div
      style={{
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 20,
        paddingRight: 20,
        display: "flex",
        flexDirection: "column",
        width: "90%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
        <BoldText
          style={{
            color: Colors.White,
            fontSize: 18,
          }}
        >
          {`${mission.title}`.toUpperCase()}
        </BoldText>
        <BoldText
          style={{
            fontSize: 18,
            marginLeft: 6,
            color: Colors.Purple,
          }}
        >
          {missionSubText}
        </BoldText>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <XPBar
          borderColor={Colors.White}
          borderWidth={1}
          height={27}
          width={280}
          percentFilled={missionPercentFilled}
          longerColorOne={true}
          customColors={[Colors.Blue, Colors.White]}
        />
        <BoldText style={{ color: Colors.Purple }}>
          {`+ ${mission.points} XP`}
        </BoldText>
      </div>
    </div>
  );
};

function XPView({
  user,
  large,
  xpMax,
}: {
  user?: User;
  large?: boolean;
  xpMax?: number;
}) {
  const percentFilled = useMemo(() => {
    if (!user) {
      return 0;
    }

    if (!xpMax || isNaN(xpMax)) {
      return 0;
    }
    if (xpMax < 0) {
      return 0;
    }

    let currentPerc = user.xp / xpMax;
    // console.log("currentPerc", currentPerc);
    if (isNaN(currentPerc)) {
      return 0;
    }
    if (currentPerc < 0.1) {
      return 0.1;
    }
    if (currentPerc < 0.7) {
      return currentPerc + 0.1;
    }
    return currentPerc;
  }, [user, xpMax]);

  if (!user) {
    return <div />;
  }

  return (
    <div
      style={{
        marginTop: 12,
        width: "100%",
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: "flex-end",
      }}
    >
      <XPBar
        borderColor={Colors.Blue}
        height={27}
        width={300}
        percentFilled={percentFilled}
      />
    </div>
  );
}

const XPBar = ({
  borderColor,
  height,
  width,
  percentFilled,
  customColors,
  borderWidth,
  longerColorOne,
}: {
  borderColor: string;
  height: number;
  width: number;
  percentFilled: number;
  customColors?: string[];
  borderWidth?: number;
  longerColorOne?: boolean;
}) => {
  var borderNumber = borderWidth ? borderWidth : 2;

  const cleanedPercent = useMemo(() => {
    if (!isNaN(percentFilled)) {
      return percentFilled;
    } else {
      return 0;
    }
  }, [percentFilled]);
  return (
    <div
      style={{
        width: width,
        height: height,
        borderWidth: borderNumber,
        borderColor: borderColor,
        borderStyle: "solid",
        borderRadius: height,
      }}
    >
      <div
        style={{
          width: width * cleanedPercent,
          height: height,

          borderRadius: height,
          background: Colors.Blue,
        }}
        //   start={longerColorOne ? { x: 0.6, y: 0.2 } : { x: 0.1, y: 0.2 }}
        //   end={{ x: 0.9, y: 1.0 }}
        //   colors={customColors ? customColors : [Colors.purple, Colors.Blue]}
      ></div>
    </div>
  );
};

enum XPKind {
  completeOnboard = "completeOnboard",
  completeProfileShort = "completeProfileShort",
  completeProfileLong = "completeProfileLong",
  addDayToPostStreak = "addDayToPostStreak",
  addDayToLoginStreak = "addDayToLoginStreak",
  postComment = "postComment",
  postLike = "postLike",
  postShare = "postShare",

  postDownload = "postDownload",

  submitChallenge = "submitChallenge",
  winChallenge = "winChallenge",
  sendVoteChallenge = "sendVoteChallenge",
  receiveVoteChallenge = "receiveVoteChallenge",

  follow25Users = "follow25Users",
  receive25Follows = "receive25Follows",

  askRaiQuestion = "askRaiQuestion",

  inviteUser = "inviteUser",

  // tara later
  inviteUserSuccess = "inviteUserSuccess",
  receiveEndorsement = "receiveEndorsement",
  pullUpAndPlayAttendance = "pullUpAndPlayAttendance",
  addSongToJukebox = "addSongToJukebox",
}
const XP_POINT_VALUES = {
  completeOnboard: 1,
  completeProfileShort: 5,
  completeProfileLong: 10,
  addDayToPostStreak: 1,
  // 10 day streak multiplies points by 1.5
  addDayToLoginStreak: 1,
  postComment: 1,
  postLike: 1,
  postShare: 1,

  postDownload: 0,
  // - 10+ downloads: 10 XP
  // - 25+ downloads: 25 XP
  // - 50+ downloads: 50 XP

  submitChallenge: 1,
  winChallenge: 50,
  sendVoteChallenge: 1,
  receiveVoteChallenge: 1,
  follow25Users: 5,
  receive25Follows: 10,
  askRaiQuestion: 1, // every 5 questions

  inviteUser: 1,

  // later
  inviteUserSuccess: 10,
  addSongToJukebox: 1,
  receiveEndorsement: 0,
  // - 5+ endorsements: 25 XP
  pullUpAndPlayAttendance: 5,
};

const MISSIONS = [
  // {
  //   kind: XPKind.completeOnboard,
  //   id: XPKind.completeOnboard,
  //   title: "Complete Onboarding",
  //   points: XP_POINT_VALUES[XPKind.completeOnboard],
  // },
  {
    kind: XPKind.completeProfileShort,
    id: XPKind.completeProfileShort,
    title: "PROFILE",
    points: XP_POINT_VALUES[XPKind.completeProfileLong],
  },
  // {
  //   kind: XPKind.completeProfileLong,
  //   id: XPKind.completeProfileLong,
  //   title: "Complete Profile",
  //   points: XP_POINT_VALUES[XPKind.completeProfileLong],
  // },
  // { kind: XPKind.addSongToJukebox, title: "Add a Song to your Jukebox" },

  {
    kind: XPKind.addDayToPostStreak,
    id: XPKind.addDayToPostStreak,
    title: "CONTENT",
    points: XP_POINT_VALUES[XPKind.addDayToPostStreak],
  },
  // {
  //   kind: XPKind.addDayToLoginStreak,
  //   id: XPKind.addDayToLoginStreak,
  //   title: "Add Day to Login Streak",
  //   points: XP_POINT_VALUES[XPKind.addDayToLoginStreak],
  // },
  // { kind: XPKind.postComment, title: "Receive a Post Comment" },
  // { kind: XPKind.postLike, title: "Receive a Post Like" },
  // { kind: XPKind.postShare, title: "Receive a Post Share" },
  // { kind: XPKind.postDownload, title: "Receive a Post Download" },
  {
    kind: XPKind.submitChallenge,
    id: XPKind.submitChallenge,
    title: "CHALLENGE SUBMISSIONS",
    points: XP_POINT_VALUES[XPKind.submitChallenge],
  },
  {
    kind: XPKind.winChallenge,
    id: XPKind.winChallenge,
    title: "CHALLENGE WINS",
    points: XP_POINT_VALUES[XPKind.winChallenge],
  },
  // {
  //   kind: XPKind.sendVoteChallenge,
  //   id: XPKind.sendVoteChallenge,
  //   title: "Vote in a Challenge",
  //   points: XP_POINT_VALUES[XPKind.sendVoteChallenge],
  // },
  // {
  //   kind: XPKind.receiveVoteChallenge,
  //   id: XPKind.receiveVoteChallenge,
  //   title: "Receive a vote in a Challenge",
  //   points: XP_POINT_VALUES[XPKind.receiveVoteChallenge],
  // },

  // {
  //   kind: XPKind.follow25Users,
  //   id: XPKind.follow25Users,
  //   title: "Follow 25 Users",
  //   points: XP_POINT_VALUES[XPKind.follow25Users],
  // },
  {
    kind: XPKind.receive25Follows,
    id: XPKind.receive25Follows,
    title: "FOLLOWS",
    points: XP_POINT_VALUES[XPKind.receive25Follows],
  },
  // { kind: XPKind.askRaiQuestion, title: "RAI discussions" },
  {
    kind: XPKind.inviteUser,
    title: "INVITES SENT",
    id: XPKind.inviteUser,
    points: XP_POINT_VALUES[XPKind.inviteUserSuccess],
  },
  // { kind: XPKind.inviteUserSuccess, title: "Invite User Success" },

  // { kind: XPKind.receiveEndorsement, title: "Receive Endorsement" },
  // {
  //   kind: XPKind.pullUpAndPlayAttendance,
  //   title: "Pull Up and Play Attendance",
  // },
];
