import EmptyAudioBackground from "../../../components/empty-audio-background";
import { BodyText, BoldText } from "../../../components/text";
import { CollabMessage } from "../../../models/collabMessage";

import { useNavigate } from "react-router-dom";
import { Player } from "video-react";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

export default function ImageFileList({
  filePosts,
  roomId,
  roomName,
  collabId,
}: {
  filePosts: CollabMessage[];
  roomId?: String;
  roomName: string;
  collabId?: string;
}) {
  let imageWidth = 110;
  const navigate = useNavigate();

  return (
    <div
      style={{
        marginTop: 10,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        padding: "2vh 0 1vh 1vw",
        borderRadius: 8,
        marginLeft: "-1vw",
        width: "calc(440px - 3vw)",
      }}
    >
      <BoldText style={{ marginBottom: 12 }}>Files</BoldText>
      {filePosts.length == 0 && (
        <div
          style={{
            opacity: 0.7,
          }}
        >
          <BodyText>No files.</BodyText>
        </div>
      )}
      <div style={{ flexDirection: "row", flexWrap: "wrap", display: "flex" }}>
        {[...filePosts].map((item) => (
          <div
            style={{
              marginRight: 12,
              marginBottom: 12,
              border: "1px solid rgba(255, 255, 255, 0.3)",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 8,
            }}
            key={item.id}
            onClick={() => {
              if (collabId) {
                navigate(
                  `/chat-files/${collabId}/${item.id}?roomName=${roomName}`
                );
              } else {
                navigate(
                  `/room-files/${roomId}/${item.id}?roomName=${roomName}`
                );
              }
            }}
          >
            {item.video ? (
              <ListVideoItem video={item.video} imageWidth={imageWidth} />
            ) : item.image ? (
              <ListImageItem image={item.image} imageWidth={imageWidth} />
            ) : (
              <EmptyAudioBackground size={imageWidth} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const ListVideoItem = ({
  video,
  imageWidth,
}: {
  video: string;
  imageWidth: number;
}) => {
  return (
    // tara here today wrong size
    <div
      style={{
        width: imageWidth,
        height: imageWidth,
        borderRadius: 8,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Player src={video} />
    </div>
  );
};

const ListImageItem = ({
  image,

  imageWidth,
}: {
  image: string;

  imageWidth: number;
}) => {
  return (
    <div>
      <img
        style={{
          width: imageWidth,
          height: imageWidth,
          borderRadius: 8,
        }}
        src={image}
      />
    </div>
  );
};
