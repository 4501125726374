import { useEffect, useMemo } from "react";
import { getResizedProfileImage } from "../constants/utils";
import { Row } from "./general";
import { BodyText } from "./text";
import { AvatarItem } from "./avatar-item";
import { User } from "../models/user";
import { doc, getFirestore, getDoc } from "firebase/firestore";

export default function AvatarList({
  avatars,
  totalCount,
  size,
  includeBlank,
}: {
  avatars: string[];
  totalCount: number;
  size?: number;
  includeBlank?: boolean;
}) {
  const finalAvatars = useMemo(() => {
    return avatars
      .filter((item) => (includeBlank ? true : item != null))
      .map((item) => (item ? getResizedProfileImage(item) : ""));
  }, [avatars, includeBlank]);

  const imageSize = useMemo(() => {
    if (size) {
      return size;
    }
    return 30;
  }, [size]);

  return (
    <Row style={{ flexDirection: "row", alignItems: "center" }}>
      {finalAvatars.map((avatar, index) => {
        return (
          <AvatarItem
            avatar={avatar}
            index={index}
            imageSize={imageSize}
            key={index}
          />
        );
      })}
      {totalCount > avatars.length && (
        <BodyText style={{ marginLeft: 4, opacity: 0.5, fontSize: 12 }}>{`+${
          totalCount - avatars.length
        }`}</BodyText>
      )}
    </Row>
  );
}

export function FetchableAvatarList({
  roomId,
  userIds,
  size,
  users,
  setUsers,
  cloudLayout,
}: {
  roomId: String;
  userIds: string[];
  size?: number;
  users: User[];
  setUsers: any;
  cloudLayout?: boolean;
}) {
  useEffect(() => {
    loadUsers();
  }, [roomId]);

  const loadUsers = async () => {
    let promises: any = [];
    userIds.forEach((userId) => {
      const docRef = doc(getFirestore(), "users", userId);
      promises.push(
        getDoc(docRef).then((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
    let users = await Promise.all(promises);
    setUsers(users);
  };

  return (
    <AvatarList
      avatars={
        users
          .map((item) => item.profilePicture)
          .filter((item) => item != null) as string[]
      }
      totalCount={users.length}
      size={size}
      includeBlank={true}
    />
  );
}
