export const getResizedProfileImage = (image: string) => {
  if (image && `${image}`.includes("/users%2F")) {
    let parts = image.split("/users%2F");
    if (parts.length > 1) {
      let parts2 = parts[1].split(".");
      if (parts2.length > 0) {
        let storageName = parts2[0];
        return image.replace(storageName, `${storageName}_200x200`);
      }
    }
  }

  return image;
};

export const textHasLink = (text: string) => {
  let kLINK_DETECTION_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  let matches = text.match(kLINK_DETECTION_REGEX);
  return matches && (matches || []).length > 0;
};
