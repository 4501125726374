import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { User } from "../models/user";

export const useUserForId = (id: string): User | null => {
  const firestore = useFirestore();
  const ref = doc(firestore, "users", id);

  const { status, data: item } = useFirestoreDocData(ref, {
    idField: "id",
  });

  return item as any;
};
