import { collection, orderBy, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { CollabMessage } from "../models/collabMessage";

export const useRoomMessages = (finalCollabId: string): CollabMessage[] => {
  const firestore = useFirestore();
  const coll = collection(firestore, "rooms", finalCollabId, "messages");
  const qref = query(coll, orderBy("createdAt", "desc"));

  const { data: posts } = useFirestoreCollectionData(qref, {
    idField: "id",
  });

  return (posts as any) || [];
};

export const useRoomAudioMessages = (
  finalCollabId: string
): CollabMessage[] => {
  const firestore = useFirestore();
  const coll = collection(firestore, "rooms", finalCollabId, "messages");
  const qref = query(coll, where("kind", "==", "audio"));

  const { data: posts } = useFirestoreCollectionData(qref, {
    idField: "id",
  });

  return (posts as any) || [];
};

export const useRoomOtherMessages = (
  finalCollabId: string
): CollabMessage[] => {
  const firestore = useFirestore();
  const coll = collection(firestore, "rooms", finalCollabId, "messages");
  const qref = query(coll, where("kind", "in", ["file", "image"]));

  const { data: posts } = useFirestoreCollectionData(qref, {
    idField: "id",
  });

  return (posts as any) || [];
};

export const useRoomLinkMessages = (finalCollabId: string): CollabMessage[] => {
  const firestore = useFirestore();
  const coll = collection(firestore, "rooms", finalCollabId, "messages");
  const qref = query(coll, where("kind", "==", "link"));

  const { data: posts } = useFirestoreCollectionData(qref, {
    idField: "id",
  });

  return (posts as any) || [];
};

export const useCollabMessages = (finalCollabId: string): CollabMessage[] => {
  const firestore = useFirestore();
  const coll = collection(firestore, "collaborationMessages");
  const qref = query(
    coll,
    where("collaborationId", "==", finalCollabId),
    orderBy("createdAt", "desc")
  );

  const { data: posts } = useFirestoreCollectionData(qref, {
    idField: "id",
  });

  return (posts as any) || [];
};
