import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import React from "react";
import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
  useFirebaseApp,
} from "reactfire";

export default function ReduxProvider({ children }: { children: any }) {
  const app = useFirebaseApp();
  const firestore = getFirestore(app);
  //   const auth = initializeAuth(app, {
  //     persistence: getReactNativePersistence(ReactNativeAsyncStorage),
  //   });
  const auth = getAuth(app);
  const storage = getStorage(app);
  const fbfunctions = getFunctions(app);

  return (
    <AuthProvider sdk={auth}>
      <StorageProvider sdk={storage}>
        <FunctionsProvider sdk={fbfunctions}>
          <FirestoreProvider sdk={firestore}>{children}</FirestoreProvider>
        </FunctionsProvider>
      </StorageProvider>
    </AuthProvider>
  );
}
