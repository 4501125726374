import { useFirestore, useFirestoreDocData, useUser } from "reactfire";

import { doc } from "firebase/firestore";
import { User } from "../models/user";

export const useMe = (): User => {
  const { data: user } = useUser();
  const ref = doc(useFirestore(), "users", user && user.uid ? user.uid : "-1");

  const { status, data: profile } = useFirestoreDocData(ref, {
    idField: "id",
  });

  const me = {
    ...user,
    ...profile,
    profileLoaded: status === "success",
  };

  return me as any;
};
