import React from "react";

export default function EmptyAudioBackground({
  size,
  height,
  iconSize,
}: {
  size: any;
  height?: number;
  iconSize?: any;
}) {
  return (
    <div
      style={{
        width: size,

        height: height ?? size,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        borderRadius: 8,

        backgroundImage: `url(${require("../assets/login-background.png")})`,
      }}
    >
      <img
        src={require("../assets/icon-white.png")}
        style={{ width: iconSize ? iconSize : size * 0.6 }}
      ></img>
    </div>
  );
}
