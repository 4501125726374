import { Column } from "../../../components/general";
import { BoldText } from "../../../components/text";
import {
  useRoomAudioMessages,
  useRoomLinkMessages,
  useRoomOtherMessages,
} from "../../../hooks/useRoomMessages";
import { Room } from "../../../models/room";
import AudioFileList from "../room-files/audio-file-list";
import ImageFileList from "../room-files/image-file-list";
import LinkList from "../room-files/link-list";

export default function RoomFiles({ room }: { room: Room }) {
  let roomId = room.id as string;
  const audioPosts = useRoomAudioMessages(roomId);
  const filePosts = useRoomOtherMessages(roomId);
  const linkPosts = useRoomLinkMessages(roomId);

  return (
    <Column
      style={{
        width: "100%",
        flex: 1,
        padding: "1vh 2vw",
      }}
    >
      <BoldText
        style={{
          paddingBottom: 14,
          textAlign: "center",
        }}
      >
        {room.name}
      </BoldText>
      <ImageFileList
        filePosts={filePosts}
        roomId={room.id as String}
        roomName={room.name}
      />
      <AudioFileList
        audioPosts={[...audioPosts, ...audioPosts]}
        roomId={room.id as String}
        roomName={room.name}
      />
      <LinkList filePosts={linkPosts} />
    </Column>
  );
}
