import {
  addDoc,
  collection,
  doc,
  getFirestore,
  increment,
  updateDoc,
} from "firebase/firestore";
import { useMemo, useState } from "react";
import { FaArrowCircleRight, FaPlusCircle } from "react-icons/fa";
import { BoldText } from "../../../components/text";
import { useMe } from "../../../hooks/useMe";
import { useCollabMessages } from "../../../hooks/useRoomMessages";
import { CollabMessage } from "../../../models/collabMessage";
import { User } from "../../../models/user";

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useMediaQuery } from "react-responsive";
import { RowApart } from "../../../components/general";
import { SimpleTextArea } from "../../../components/inputs";
import { Colors } from "../../../constants/colors";
import { textHasLink } from "../../../constants/utils";
import { useUserForId } from "../../../hooks/useUsers";
import { Collaboration } from "../../../models/collaboration";
import ChatItem from "./chat-item";

export default function MessagesChatDisplay({
  collaboration,
  collabId,
  newMessages,
  setNewMessages,
}: {
  collaboration: Collaboration;
  collabId: string;
  newMessages: CollabMessage[];
  setNewMessages: any;
}) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const me = useMe();

  const roomMessages = useCollabMessages(collabId as any);
  const userId = me && me.id ? me.id : "-1";
  const [textInput, setTextInput] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const otherUserId = useMemo(() => {
    if (collaboration) {
      let ids = collaboration.userIds.filter((item) => item != userId);
      if (ids.length > 0) {
        return ids[0];
      }
    }

    return "-1";
  }, [userId, collaboration]);

  const otherUser = useUserForId(otherUserId);

  const createMessage = async (
    text: string,
    roomId: string,
    image?: string,
    audio?: string,
    audioTitle?: string,
    video?: string
  ) => {
    let hasLink = textHasLink(text);

    let newMessage: CollabMessage = {
      collaborationId: collabId,
      userId: userId,
      user: {
        _id: userId,
        name: me.username,
        // @ts-ignore
        avatar: me.profilePicture ? me.profilePicture : null,
      },
      text: text,
      createdAt: new Date(),
      archived: false,
      image: image ? image : null,
      audio: audio ? audio : null,
      audioTitle: audioTitle ? audioTitle : null,
      recipientId: otherUserId,
      unread: true,
      // @ts-ignore
      kind: audio
        ? "audio"
        : image
        ? "image"
        : video
        ? "video"
        : hasLink
        ? "link"
        : "text",
    };
    let res = await addDoc(
      collection(getFirestore(), "collaborationMessages"),
      newMessage
    );
    if (otherUserId == "RAI") {
      setTimeout(async () => {
        let raiMessage: CollabMessage = {
          collaborationId: collabId,
          userId: "RAI",
          user: {
            _id: "RAI",
            name: "RAI",
            avatar: "RAI",
          },
          text: "Thanks for using RAI to set up your profile! I'll let you know if I have any new profile setup questions for you. To chat with me about other topics, please find me in the Search tab.",
          createdAt: new Date(),
          archived: false,
          image: null,
          audio: null,
          recipientId: userId,
          unread: false,
        };
        let raiRes = await addDoc(
          collection(getFirestore(), "collaborationMessages"),
          raiMessage
        );

        setNewMessages([
          ...newMessages,
          { ...newMessage, id: res.id },
          { ...raiMessage, id: raiRes.id },
        ]);
      }, 1000);
    } else {
      // @ts-ignore
      setNewMessages([...newMessages, { ...newMessage, id: res.id }]);
      updateLatestChat(
        text
          ? text
          : image
          ? `${me.username} sent an image`
          : video
          ? `${me.username} sent a video`
          : audio
          ? `${me.username} sent an audio file`
          : ""
      );
    }
  };

  const updateLatestChat = async (text: string) => {
    if (text) {
      let updates: any = {
        lastupdate: new Date(),
        subheading: text,
        unreadCount: increment(1),
        lastRecipientId: otherUserId,
      };

      const ref = doc(getFirestore(), "collaborations", collabId);
      await updateDoc(ref, {
        ...updates,
      });

      const userRef = doc(getFirestore(), "users", otherUserId);
      await updateDoc(userRef, {
        unreadChatCount: increment(1),
      });

      if (otherUser) {
        sendPushNotification(otherUser, text);
      }
    }
  };

  const limitStringToNumCharacters = (str: string, num: number) => {
    if (str.length > num) {
      return str.substring(0, num) + "...";
    }
    return str;
  };

  const sendPushNotification = async (otherUser: User, text: string) => {
    if (otherUser.pushToken) {
      const message = {
        to: otherUser.pushToken,
        sound: "default",
        title: `New message from ${me.username}`,
        body: limitStringToNumCharacters(text, 174 - me.username.length - 17),
        data: {
          collabId: collabId,
        },
      };

      await fetch("https://exp.host/--/api/v2/push/send", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Accept-encoding": "gzip, deflate",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      });
    }
  };

  const getExtension = (uri: string): string => {
    if (uri) {
      let split = uri.split(".");
      if (split.length > 0) {
        return split[split.length - 1];
      }
    }
    return "";
  };

  const uploadFileAsync = async (uri: string, file: any, prefix: string) => {
    try {
      const filename = `${Math.floor(
        Math.random() * 10000000 + 1
      )}.${getExtension(uri)}`;

      const uploadRef = ref(getStorage(), `${prefix}/${filename}`);

      await uploadBytesResumable(uploadRef, file);

      const url = await getDownloadURL(uploadRef);

      return url;
    } catch (err) {
      console.log("upload err", err);
    }
  };

  const filteredMessages = useMemo(() => {
    var newItems = newMessages.filter(
      (msg: any) =>
        !(roomMessages || []).map((item) => item.id).includes(msg.id)
    );

    let items = [...(roomMessages || []), ...newItems]
      .filter((item) => item.createdAt)
      .map((item) => ({
        ...item,
        createdAt: item.createdAt.seconds
          ? new Date(item.createdAt.seconds * 1000)
          : item.createdAt,
        user: item.user
          ? {
              ...item.user,
              id: item.user._id.toLowerCase(),
            }
          : {
              id: "system",
              name: "system",
            },
      }));

    items.sort(function (a, b) {
      return a.createdAt - b.createdAt;
    });

    return items;
  }, [roomMessages, newMessages]);

  const handleChangeFile = async (event: any) => {
    let file = event.target.files[0];

    setImageLoading(true);
    const uploadUrl = await uploadFileAsync(file.name, file, "chat");
    setImageLoading(false);

    if (file.type.includes("image")) {
      createMessage("", collabId, uploadUrl);
    } else if (file.type.includes("video")) {
      createMessage("", collabId, "", "", "", uploadUrl);
    } else if (file.type.includes("audio")) {
      createMessage("", collabId, "", uploadUrl, file.name);
    } else {
      // tara here alert error
    }
  };

  return (
    <div style={{ padding: "2vh 2vw", minHeight: "100vh" }}>
      <div style={{ paddingBottom: 150 }}>
        {filteredMessages.map((message: any) => {
          return (
            <ChatItem
              message={message}
              collabId={collabId}
              key={message.id}
              otherUserId={otherUserId}
              roomName={
                otherUser ? `${otherUser.username} Messages` : "Messages"
              }
            />
          );
        })}
      </div>

      <div
        style={{
          position: "fixed",
          right: "2vw",
          left: isMobile ? "2vw" : "calc(400px + 6vw)",
          bottom: 14,
          backgroundColor: Colors.Background,
          borderRadius: 20,
          border: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <SimpleTextArea
          style={{
            backgroundColor: Colors.Transparent,
            width: "100%",
            height: 50,
            border: "none",
            padding: "1vh",
          }}
          onChange={(evt) => setTextInput(evt.target.value)}
          value={textInput}
        />
        <RowApart style={{}}>
          <div
            style={{
              opacity: 0.8,

              margin: 8,
            }}
          >
            <input
              type="file"
              id="actual-btn"
              onChange={handleChangeFile}
              hidden
            />
            {imageLoading ? (
              <BoldText>Uploading...</BoldText>
            ) : (
              // @ts-ignore
              <label for={"actual-btn"}>
                <FaPlusCircle
                  size={28}
                  style={{ color: Colors.White, cursor: "pointer" }}
                />
              </label>
            )}
          </div>

          <div
            style={{
              backgroundColor: "white",
              margin: 8,
              width: 28,
              height: 28,
              borderRadius: 28 / 2,
              opacity: imageLoading ? 0.5 : 1,
            }}
            onClick={() => {
              createMessage(textInput, collabId);
              setTextInput("");
            }}
          >
            <FaArrowCircleRight
              size={28}
              style={{ color: Colors.Blue, cursor: "pointer" }}
            />
          </div>
        </RowApart>
      </div>
    </div>
  );
}
