import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useState } from "react";
import { IoMdAdd, IoMdClose, IoMdSearch } from "react-icons/io";
import { AvatarItem } from "../../../components/avatar-item";
import { OutlineButton } from "../../../components/buttons";
import { Column, Row } from "../../../components/general";
import { SimpleInput } from "../../../components/inputs";
import { BodyText, BoldText } from "../../../components/text";
import { Colors } from "../../../constants/colors";
import { useMe } from "../../../hooks/useMe";
import { bodyTextForKind } from "../../../models/activity";
import { Room } from "../../../models/room";
import { User } from "../../../models/user";
import { typesenseSearch } from "../../../services/typesense-service";

export default function RoomEdit({
  room,
  setRoom,
  members,
  setMembers,
  closeRoom,
  addSystemMessage,
  selectUser,
}: {
  room: Room;
  setRoom: any;
  members: User[];
  setMembers: any;
  closeRoom: any;
  addSystemMessage: any;
  selectUser: any;
}) {
  const [addingMember, setAddingMember] = useState(false);
  const [title, setTitle] = useState(room.name as string);
  let roomId = room.id as string;
  const me = useMe();

  useEffect(() => {
    if (room) {
      setTitle(room.name);
    }
  }, [room?.name]);

  const leaveRoom = async () => {
    if (!room) return;
    if (room.initiatorId == me.id && room.userIds.length == 1) {
      let ref = doc(getFirestore(), "rooms", roomId);
      await updateDoc(ref, {
        archived: true,
      });

      closeRoom();
    } else {
      let myId = me.id as string;
      let unreadCounts: any = { ...room.unreadCounts };
      unreadCounts[myId] = 0;
      setMembers(members.filter((item) => item.id != me.id));
      let newUserIds = room.userIds.filter((item) => item != me.id);
      let ref = doc(getFirestore(), "rooms", roomId);
      await updateDoc(ref, {
        userIds: newUserIds,
        unreadCounts: unreadCounts,
      });

      closeRoom();
    }
  };

  const notifyMemberAdded = async (user: any) => {
    var createActivity = httpsCallable(getFunctions(), "createActivity");
    let activityKind = "room-add-member";
    createActivity({
      actor: {
        id: me.id,
        username: me.username,
        profilePicture: me.profilePicture,
      },
      recipientId: user.id,
      kind: activityKind,

      bodyText: bodyTextForKind(activityKind, me),
      extraVars: {
        roomId: room.id,
      },
    });

    const ref = doc(getFirestore(), "users", user.id);
    updateDoc(ref, {
      unreadRoomChatCount: 1,
    });
    addSystemMessage(`${me.username} added ${user.username}`);
  };

  const addUserToRoom = async (user: any) => {
    let newUserIds = [...room.userIds, user.id];
    notifyMemberAdded(user);

    let unreadCounts = { ...room.unreadCounts };
    unreadCounts[user.id] = 1;

    updateRoom({ userIds: newUserIds, unreadCounts: unreadCounts });
    setRoom({
      ...room,
      userIds: newUserIds,
      unreadCounts: unreadCounts,
    });
    setMembers([...members, user]);

    setAddingMember(false);
  };

  const updateRoom = async (updates: any) => {
    await updateDoc(doc(getFirestore(), "rooms", roomId), {
      ...updates,
    });
  };

  return (
    <Column
      style={{
        width: "100%",
        flex: 1,
        padding: "1vh 0vw",
      }}
    >
      {addingMember ? (
        <div style={{}}>
          <BoldText
            style={{
              paddingBottom: 14,
              textAlign: "center",
              marginBottom: "2vh",
              paddingLeft: "2vw",
            }}
          >
            Add Member
          </BoldText>
        </div>
      ) : (
        <div
          style={{
            marginTop: -14,
            marginBottom: "2vh",
            paddingLeft: "2vw",
          }}
        >
          <SimpleInput
            value={title}
            onChange={(evt) => {
              setTitle(evt.target.value);
            }}
            onBlur={() => {
              if (title.length > 1) {
                updateRoom({ name: title });
                setRoom({ ...room, name: title });
                addSystemMessage(
                  `${me.username} changed the room name to ${title}`
                );
              }
            }}
            style={{
              padding: 0,
              margin: 0,
              background: Colors.Transparent,
              border: "none",
              fontFamily: "Inconsolata",
              marginTop: 0,
              fontSize: 20,
              fontWeight: 700,
            }}
          />
        </div>
      )}

      {addingMember ? (
        <div>
          <UserSearch
            onSelectUser={(user: any) => {
              addUserToRoom(user);
            }}
          />
        </div>
      ) : (
        <Row style={{ flexWrap: "wrap" }}>
          {members.map((member, index) => {
            return (
              <div
                style={{
                  width: 110,
                  marginBottom: "2vh",
                }}
                onClick={() => selectUser(member)}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <AvatarItem
                    avatar={member.profilePicture as any}
                    index={0}
                    imageSize={80}
                  />
                  <BodyText style={{ fontSize: 13, marginTop: "1vh" }}>
                    {member.username}
                  </BodyText>
                </div>
              </div>
            );
          })}

          <div
            style={{
              width: 110,
              marginBottom: "2vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  height: 78,
                  width: 78,
                  borderRadius: 78 / 2,
                  border: "1px solid white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setAddingMember(true)}
              >
                <IoMdAdd style={{ fontSize: 25 }} />
              </div>
            </div>
          </div>
        </Row>
      )}

      {addingMember ? (
        <div></div>
      ) : (
        <div
          style={{
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            width: "calc(100% - 4vw)",
            padding: "3vh 2vw",
          }}
        >
          <OutlineButton
            style={{
              flex: 1,
              backgroundColor: "transparent",
              borderColor: "red",
            }}
            onClick={leaveRoom}
          >
            <BoldText style={{ marginTop: 4, marginBottom: 4, color: "red" }}>
              Leave Room
            </BoldText>
          </OutlineButton>
        </div>
      )}

      {addingMember && (
        <div
          style={{
            position: "absolute",
            top: 0,
            background: Colors.Background,
          }}
        >
          <IoMdClose
            style={{
              color: Colors.Blue,
              fontSize: 34,
              padding: "3vh 2vw 1vh 2vw",

              cursor: "pointer",
            }}
            onClick={() => setAddingMember(false)}
          />
        </div>
      )}
    </Column>
  );
}

function UserSearch({ onSelectUser }: { onSelectUser: any }) {
  const [loading, setLoading] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [query, setQuery] = useState("");

  const getSuggestions = useCallback(async (q: string) => {
    if (typeof q !== "string" || q.length < 2) {
      setSuggestionsList([]);
      return;
    }
    setLoading(true);
    let userResults: any = await typesenseSearch("users", q, []);
    let results = userResults.docs
      .map((item: any) => ({ ...item, title: item.username.trim() }))
      .filter((item: any) => item.title)
      .splice(0, 5);
    setSuggestionsList(results);
    setLoading(false);
  }, []);

  return (
    <div style={{ padding: "0vh 2vw" }}>
      <div
        style={{
          background: Colors.TransparentWhite1,
          marginBottom: "2vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: 0,
          borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <IoMdSearch style={{ fontSize: 20, marginLeft: 8 }} />
        <SimpleInput
          value={query}
          onChange={(evt) => {
            setQuery(evt.target.value);
            getSuggestions(evt.target.value);
          }}
          style={{
            width: `calc(340px - 6vw)`,
            padding: "6px 12px 6px 4px",
            background: Colors.Transparent,
            border: "none",
            fontFamily: "Inconsolata",
            marginTop: 0,
          }}
        />
      </div>

      <Column>
        {suggestionsList.map((user: any) => (
          <div style={{ cursor: "pointer" }} onClick={() => onSelectUser(user)}>
            <UserResult user={user} />
          </div>
        ))}
      </Column>
    </div>
  );
}

function UserResult({ user }: { user: any }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        borderWidth: 1,

        borderColor: Colors.TransparentWhite4,
        borderRadius: 40,
        marginBottom: 15,
      }}
    >
      <AvatarItem avatar={user.profilePicture} index={0} imageSize={45} />
      <div style={{ padding: "0px 15px" }}>
        <BoldText
          style={{
            color: "#fff",

            fontSize: 18,
          }}
        >
          {user.title}
        </BoldText>
        <BodyText style={{ fontSize: 12 }}>
          {((user as any).musicianType || []).length > 0
            ? `${((user as any).musicianType || []).join(", ").toLowerCase()}`
            : `${(user as any).location}`}
        </BodyText>
      </div>
    </div>
  );
}
