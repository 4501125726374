import { IoPerson } from "react-icons/io5";
import { BoldText } from "./text";

export function RoomAvatar({
  roomName,
  imageSize,
}: {
  roomName: string;
  imageSize: number;
}) {
  return (
    <div
      style={{
        display: "flex",

        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundImage: `url(${require("../assets/background-circle.png")})`,
        backgroundSize: "cover",
        width: imageSize,
        height: imageSize,
        borderRadius: imageSize / 2,
      }}
    >
      <BoldText
        style={{
          color: "white",
          fontSize: imageSize * 0.7,
        }}
      >
        {roomName.length > 0 ? roomName[0] : "R"}
      </BoldText>
    </div>
  );
}
