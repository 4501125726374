import styled from "styled-components";
import { Colors } from "../constants/colors";
import { ErrorText, WhiteH3 } from "./text";

export const SimpleInput = styled.input`
  color: ${Colors.White};
  font-family: "Helvetica";
  background: ${Colors.DarkBlack};
  border: none;
  border-bottom: 1px solid #c6c6c4;
  box-sizing: border-box;
  width: 472px;
  height: 48px;
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
  color: white;

  &:focus {
    outline: none;
  }
`;

export const SimpleTextArea = styled.textarea`
  color: ${Colors.White};
  font-family: "Helvetica";
  box-sizing: border-box;
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;

  &:focus {
    outline: none;
  }
`;

export function LabeledInput({
  children,
  value,
  setValue,
  inputStyles,
  secureEntry,
  onFocus,
  onBlur,
  error,
  disabled,
  inputMode,
  autoFocus,
}: {
  children: any;
  value: string;
  setValue: any;
  inputStyles?: any;
  secureEntry?: boolean;
  onFocus?: any;
  onBlur?: any;
  error?: string;
  disabled?: boolean;
  inputMode?: "email" | "numberic" | "tel";
  autoFocus?: boolean;
}) {
  return (
    <div className="column" style={{ marginBottom: 18 }}>
      <WhiteH3>{children}</WhiteH3>
      <SimpleInput
        // @ts-ignore
        inputMode={inputMode ? inputMode : "text"}
        value={value}
        disabled={disabled}
        type={secureEntry ? "password" : "text"}
        style={{ ...inputStyles }}
        onChange={(evt) => setValue(evt.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
}
