import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Player } from "video-react";
import { Column } from "../../../components/general";
import { BoldText } from "../../../components/text";
import { Colors } from "../../../constants/colors";
import { Post } from "../../../models/post";
import { LargeAudioPlayer } from "./message-item-detail";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

function FileDetail() {
  const { fileId } = useParams();
  let navigate = useNavigate();
  const [file, setFile] = useState<Post>();
  let [searchParams, setSearchParams] = useSearchParams();

  const folderName = useMemo(() => {
    if (searchParams) {
      return searchParams.get("folderName") ?? "";
    }
    return "";
  }, [searchParams]);

  useEffect(() => {
    fetchFile();
  }, [fileId]);

  const fetchFile = async () => {
    if (!fileId) return;
    let ref = doc(getFirestore(), "posts", fileId);
    let snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      setFile({ ...snapshot.data(), id: fileId } as Post);
    }
  };

  const name = useMemo(() => {
    if (file) {
      if (file.uploadTitle) {
        return file.uploadTitle;
      }
      let base = file.image ?? "";
      if (file.video) {
        base = file.video;
      }
      if (base.split("%2F").length > 1) {
        let newBase = base.split("%2F")[1];
        return newBase.split("?")[0];
      }
      return file.kind;
    }
    return "";
  }, [file]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <div>
        <IoMdClose
          style={{
            color: Colors.Blue,
            fontSize: 34,
            padding: "3vh 2vw 1vh 2vw",

            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        />
      </div>

      <Column style={{ flex: 1, padding: "1vh 2vw" }}>
        <div>
          <BoldText
            style={{ fontSize: 18 }}
          >{`${folderName} > ${name}`}</BoldText>
        </div>
        {file && file.video ? (
          <div style={{ marginLeft: "35vw", marginTop: "2vh", width: "25vw" }}>
            <Player playsInline src={file.video} />
          </div>
        ) : (
          file &&
          file.image && (
            <div style={{ marginLeft: "3vw", marginTop: "2vh" }}>
              <img
                style={{
                  width: "90vw",

                  height: "80vh",
                  objectFit: "contain",
                }}
                src={file.image}
              ></img>
            </div>
          )
        )}

        {file && file.audio && (
          <div style={{ marginLeft: "8vw", marginTop: "2vh", width: "80vw" }}>
            <LargeAudioPlayer
              audioUrl={file.audio}
              audioImage={file.audioThumbnail}
            />
          </div>
        )}
      </Column>
    </div>
  );
}

export default FileDetail;
