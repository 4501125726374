export enum Colors {
  Blue = "#669AFF",
  Purple = "#D886FF",
  DarkPurple = "#494C8A",
  DarkBlack = "#141414",
  Background = `#221F29`,
  // TranslucentGreen = "#B6CDBF",
  // LightestGreen = `#EFF9F3`,
  // Blue = "#3074A5",
  // DarkBlack = "#2b2b2b",
  // DividerGray = "#8D8D8A",
  // HeaderGray = "#ededed",
  // LightGray = "#f8f8f8",
  ErrorRed = "#E73C60",
  // LightRed = "#FAF1F3",
  White = "#fff",
  Lightblack = "#1E1E1E",
  TransparentWhite4 = "rgba(255, 255, 255, 0.4)",
  TransparentWhite1 = "rgba(255, 255, 255, 0.1)",
  Transparent = "rgba(255, 255, 255, 0)",
}
