import { Link, useNavigate } from "react-router-dom";
import { Row } from "../../components/general";
import { BodyText, BoldText } from "../../components/text";
import { SecondaryButton, WideButton } from "../../components/buttons";
import { useMediaQuery } from "react-responsive";

function LandingPage() {
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flex: 1,

        flexDirection: "column",
        backgroundImage: isMobile
          ? `url(${require("../../assets/login-background-mobile.png")})`
          : `url(${require("../../assets/login-background.png")})`,
        backgroundSize: "cover",
      }}
    >
      <div
        style={
          isMobile
            ? {
                marginBottom: "2vh",
                marginTop: "5vh",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
              }
            : {
                marginBottom: "2vh",
                marginTop: "2vh",
              }
        }
      >
        <div
          style={{
            paddingLeft: "2vh",
            paddingRight: "2vh",
          }}
        >
          <img
            src={require("../../assets/white-logo-large.png")}
            style={{ width: 220 }}
          />
        </div>
      </div>
      <div
        style={{
          flex: 1,

          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            paddingLeft: isMobile ? "8vw" : "2vw",
            paddingBottom: "8vh",
            alignItems: "flex-start",
          }}
        >
          <div
            style={
              isMobile
                ? {
                    maxWidth: "90vw",
                    marginTop: 20,
                    marginBottom: 40,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }
                : { maxWidth: 400, marginTop: 200 }
            }
          >
            <BoldText
              style={{
                fontSize: 60,
                fontWeight: 900,
                wordSpacing: -12,
                textAlign: isMobile ? "center" : "left",
              }}
            >
              {/* {`Realm: Portal to a New Music Industry.`.toUpperCase()} */}
              {`Your next level awaits.`.toUpperCase()}
            </BoldText>
            <BodyText
              style={{
                margin: isMobile ? "2vh 0px 4vh" : "2vh 0px 8vh",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              Founded by legendary musician and producer DANJA, Realm is a
              portal for music collaboration. Dive into a world where talent
              meets opportunity, creativity meets technology, and every
              challenge propels you to your next level.
            </BodyText>
            <a
              href={"https://testflight.apple.com/join/598FKK1g"}
              target="_blank"
            >
              <WideButton style={{ marginBottom: 0, width: 350 }}>
                DOWNLOAD
              </WideButton>
            </a>
          </div>
          <div
            style={
              isMobile
                ? {
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 20,
                    justifyContent: "center",
                    alignSelf: "center",
                  }
                : { display: "flex", flexDirection: "row", marginTop: 20 }
            }
          >
            <a
              href={"https://testflight.apple.com/join/598FKK1g"}
              target="_blank"
            >
              <img
                src={require("../../assets/ios-download.png")}
                style={{ height: 50, marginRight: 20, objectFit: "contain" }}
              ></img>
            </a>
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.tarawilson.realm"
              }
              target="_blank"
            >
              <img
                src={require("../../assets/android-download.png")}
                style={{ height: 50, objectFit: "contain" }}
              ></img>
            </a>
          </div>
        </div>

        {!isMobile && (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../assets/sample-app.png")}
              style={{ height: "70vh", objectFit: "contain" }}
            ></img>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

function Footer() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  if (isMobile) {
    return (
      <div
        style={{
          background: "white",
          padding: 30,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ marginBottom: 30 }}>
          <BoldText
            style={{
              fontSize: 35,
              fontWeight: 900,
              wordSpacing: -3,
              marginBottom: 12,
              maxWidth: 160,
              color: "black",
              lineHeight: 0.95,
            }}
          >
            {`STAY IN THE KNOW`}
          </BoldText>
          <BodyText
            style={{
              fontSize: 16,
              marginBottom: 14,
              color: "black",
            }}
          >
            Sign up to get the latest releases and updates from Realm
          </BodyText>
          <BodyText style={{ color: "black" }}>
            <span style={{ opacity: 0.5 }}>(coming soon!)</span>
          </BodyText>
          {/* sign up here  */}
        </div>

        <div
          style={{
            marginBottom: 14,

            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Link
            to="/login"
            style={{
              textDecoration: "none",
              textDecorationLine: "none",
            }}
          >
            <BoldText
              style={{
                fontSize: 16,
                color: "black",
              }}
            >
              Home
            </BoldText>
          </Link>
        </div>
        <div style={{}}>
          <BoldText
            style={{
              fontSize: 16,
              marginBottom: 14,
              color: "black",
            }}
          >
            FAQ
            <span style={{ opacity: 0.5, marginLeft: 4 }}>(coming soon!)</span>
          </BoldText>
          <BoldText
            style={{
              fontSize: 16,
              color: "black",
            }}
          >
            Terms & Conditions
            <span style={{ opacity: 0.5, marginLeft: 4 }}>(coming soon!)</span>
          </BoldText>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <img
            src={require("../../assets/icon-black.png")}
            style={{ width: 70 }}
          />
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        background: "black",
        padding: 50,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          flex: 0.5,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Link
          to="/login"
          style={{
            textDecoration: "none",
            textDecorationLine: "none",
          }}
        >
          <BoldText
            style={{
              fontSize: 16,
            }}
          >
            Home
          </BoldText>
        </Link>
      </div>
      <div style={{ flex: 2 }}>
        <BoldText
          style={{
            fontSize: 16,
            marginBottom: 14,
          }}
        >
          FAQ
          <span style={{ opacity: 0.5, marginLeft: 4 }}>(coming soon!)</span>
        </BoldText>
        <BoldText
          style={{
            fontSize: 16,
          }}
        >
          Terms & Conditions
          <span style={{ opacity: 0.5, marginLeft: 4 }}>(coming soon!)</span>
        </BoldText>
      </div>

      <div style={{ flex: 2 }}>
        <BoldText
          style={{
            fontSize: 24,
            fontWeight: 900,
            wordSpacing: -2,
            marginBottom: 14,
          }}
        >
          STAY IN THE KNOW
        </BoldText>
        <BodyText
          style={{
            fontSize: 16,
            marginBottom: 14,
          }}
        >
          Sign up to get the latest releases and updates from Realm
        </BodyText>
        <BodyText>
          <span style={{ opacity: 0.5 }}>(coming soon!)</span>
        </BodyText>
        {/* sign up here  */}
      </div>
    </div>
  );
}

export default LandingPage;
