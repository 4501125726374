import { useLocation, useNavigate } from "react-router-dom";
import {
  GeneralButton,
  OutlineButton,
  UnderlinedLinkButton,
} from "../components/buttons";
import { Row } from "../components/general";
import { Colors } from "../constants/colors";
import { AvatarItem } from "../components/avatar-item";
import { useMe } from "../hooks/useMe";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { useState } from "react";
import { BoldText } from "../components/text";
import { getAuth, signOut } from "firebase/auth";

function Header() {
  let navigate = useNavigate();
  const location = useLocation();
  const me = useMe();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div
      style={{
        padding: "2vh 2vw",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <div>
        <img
          src={require("../assets/icon-title-white.png")}
          style={{ width: 240 }}
        />
        <Row
          style={{
            justifyContent: "flex-start",
            marginTop: "2vh",
            marginBottom: "2vh",
            paddingLeft: "1vh",
          }}
        >
          <GeneralButton
            style={
              !location.pathname.includes("files")
                ? { marginRight: "1vw" }
                : {
                    backgroundColor: Colors.DarkBlack,
                    color: Colors.White,
                    marginRight: "1vw",
                  }
            }
            onClick={() => {
              navigate(`/room`);
            }}
          >
            MESSAGES
          </GeneralButton>
          <GeneralButton
            style={
              location.pathname.includes("files")
                ? {}
                : {
                    backgroundColor: Colors.DarkBlack,
                    color: Colors.White,
                  }
            }
            onClick={() => {
              navigate(`/files`);
            }}
          >
            FILES
          </GeneralButton>
        </Row>
      </div>
      <div>
        <Popover
          isOpen={isPopoverOpen}
          positions={["left", "bottom"]}
          padding={10}
          reposition={false}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={Colors.DarkPurple}
              arrowSize={10}
              arrowStyle={{}}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <div
                style={{ backgroundColor: Colors.DarkPurple, padding: "1vw" }}
              >
                <BoldText>{me.username}</BoldText>
                <UnderlinedLinkButton
                  style={{
                    color: "white",
                    marginTop: 12,
                    textDecorationColor: "white",
                  }}
                  onClick={() => {
                    signOut(getAuth());
                    navigate("/login");
                  }}
                >
                  Sign out
                </UnderlinedLinkButton>
              </div>
            </ArrowContainer>
          )}
        >
          <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
            <AvatarItem
              avatar={me && me.profilePicture ? me.profilePicture : ""}
              imageSize={50}
              index={0}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default Header;
