import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BodyText } from "../../../components/text";
import { useMe } from "../../../hooks/useMe";
import { CollabMessage } from "../../../models/collabMessage";

import moment from "moment";
import { Player } from "video-react";
import { AvatarItem } from "../../../components/avatar-item";
import { Column, Row, RowApart } from "../../../components/general";
import { Colors } from "../../../constants/colors";
import { ListAudioItem } from "../room-files/audio-file-list";
// @ts-ignore
import Linkify from "react-linkify";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

export default function ChatItem({
  message,
  roomId,
  roomName,
  collabId,
  otherUserId,
}: {
  message: CollabMessage;
  roomId?: string;
  roomName?: string;
  collabId?: string;
  otherUserId?: string;
}) {
  const me = useMe();
  const navigate = useNavigate();

  const isMyMessage = useMemo(() => {
    return message.user && message.user._id == me.id;
  }, [me.id, message]);

  if (message.system) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: 0.6,
          margin: "10px 0px",
        }}
      >
        <Linkify>
          <BodyText>{message.text}</BodyText>
        </Linkify>
      </div>
    );
  }

  return (
    <RowApart
      style={{
        margin: isMyMessage ? "1vh 0px" : "2vh 0px",
        padding: "2vh",
        borderRadius: 20,
        backgroundColor: isMyMessage
          ? Colors.Background
          : "rgba(240, 240, 240, 0.1)",
        alignItems: "flex-start",
      }}
    >
      <Row>
        <AvatarItem
          avatar={message.user ? message.user.avatar : ""}
          index={0}
          imageSize={40}
        />

        <Column style={{ marginLeft: "2vh", paddingTop: 4 }}>
          {message.text && (
            <Linkify>
              <BodyText>{message.text}</BodyText>
            </Linkify>
          )}
          {message.image && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (roomId) {
                  navigate(
                    `/room-files/${roomId}/${message.id}?roomName=${roomName}`
                  );
                } else if (collabId) {
                  navigate(
                    `/chat-files/${collabId}/${message.id}?roomName=${roomName}`
                  );
                }
              }}
            >
              <img
                src={message.image}
                style={{ width: 180, borderRadius: 12 }}
              />
            </div>
          )}
          {message.audio && (
            <div
              style={{
                width: 450,
                marginTop: -10,
              }}
            >
              <ListAudioItem
                audioUrl={message.audio as string}
                audioTitle={message.audioTitle as string}
                username={message.user.name}
                styles={{ padding: 0, borderWidth: 0 }}
              />
            </div>
          )}
          {message.video && (
            <div
              style={{
                width: 400,
              }}
            >
              <Player playsInline src={message.video} />
            </div>
          )}
        </Column>
      </Row>

      <div>
        <BodyText style={{ opacity: 0.6 }}>
          {moment(message.createdAt).format("h:mm a")}
        </BodyText>
      </div>
    </RowApart>
  );
}
