import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PrimaryButton,
  SecondaryButton,
  UnderlinedLinkButton,
  WideButton,
} from "../../components/buttons";
import { Column, Row } from "../../components/general";
import { LabeledInput } from "../../components/inputs";
import { MonoText } from "../../components/text";
import { useMediaQuery } from "react-responsive";

const LOGIN = "login";
const RESET_PASS = "reset";
function Login() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  let navigate = useNavigate();
  const [authType, setAuthType] = React.useState(LOGIN);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  useEffect(() => {
    let userEmail = localStorage.getItem("realmEmail");
    if (userEmail) {
      setEmail(userEmail);
      setAuthType(LOGIN);
    }
  }, []);

  const isValid = React.useMemo(() => {
    if (emailError || firstNameError || lastNameError || passwordError) {
      return false;
    }
    return true;
  }, [emailError, firstNameError, lastNameError, passwordError]);

  const submit = async () => {
    localStorage.setItem("realmEmail", email);
    try {
      switch (authType) {
        case LOGIN:
          await signInWithEmailAndPassword(getAuth(), email, password);
          navigate("/");
          break;

        case RESET_PASS:
          await sendPasswordResetEmail(getAuth(), email);
          toast.success(`Reset password instructions sent to ${email}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          setAuthType(LOGIN);
          break;
      }
    } catch (err) {
      setEmail("");
      setPassword("");
      let message = (err as any).message;
      if (message.includes("may have been deleted")) {
        message = "A user with that email does not exist.";
      }
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };

  React.useEffect(() => {
    if (authType == LOGIN) {
      if (emailError) {
        setEmailError("");
      }
      if (firstNameError) {
        setFirstNameError("");
      }
      if (lastNameError) {
        setLastNameError("");
      }
      if (passwordError) {
        setPasswordError("");
      }
    }
  }, [authType]);

  const validateAll = () => {
    validateEmail();
    validatePassword();
  };

  const validateEmail = () => {
    // if (authType == SIGN_UP) {
    //   if (!(email.includes("@") && email.includes(".") && email.length > 4)) {
    //     setEmailError("Invalid email.");
    //   }
    // }
  };

  const validatePassword = () => {
    // if (authType == SIGN_UP) {
    //   if (password.length < 6) {
    //     setPasswordError("Password must be at least 6 characters.");
    //   }
    // }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flex: 1,

        alignItems: "center",
        flexDirection: "column",
        backgroundImage: isMobile
          ? `url(${require("../../assets/login-background-mobile.png")})`
          : `url(${require("../../assets/login-background.png")})`,
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          marginBottom: 40,
          marginTop: 40,
        }}
      >
        <div
          style={{
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <img
            src={require("../../assets/white-logo-large.png")}
            style={{ width: "100%" }}
          />
          <img
            src={require("../../assets/logo-subtext.png")}
            style={{ width: "100%", marginTop: 40 }}
          />
        </div>
      </div>
      <Row
        style={{
          flex: 1,

          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ padding: 40 }}>
          {authType != LOGIN && (
            <MonoText
              style={{
                marginBottom: 70,
                textAlign: "center",
                fontSize: 20,
              }}
            >
              {authType == RESET_PASS
                ? "Reset your password"
                : authType == LOGIN
                ? "Somewhere within the noise."
                : "Sign up"}
            </MonoText>
          )}
          <LabeledInput
            value={email}
            setValue={(val: any) => {
              setEmail(val);
              if (emailError) {
                setEmailError("");
              }
            }}
            inputStyles={
              isMobile
                ? { backgroundColor: "rgba(255, 255, 255, 0.2)", width: "75vw" }
                : { backgroundColor: "rgba(255, 255, 255, 0.2)" }
            }
            error={emailError}
            onBlur={validateEmail}
          >
            Email
          </LabeledInput>

          {authType != RESET_PASS && (
            <LabeledInput
              secureEntry={true}
              value={password}
              setValue={(val: any) => {
                setPassword(val);
                if (passwordError) {
                  setPasswordError("");
                }
              }}
              inputStyles={
                isMobile
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      width: "75vw",
                    }
                  : { backgroundColor: "rgba(255, 255, 255, 0.2)" }
              }
              error={passwordError}
              onBlur={validatePassword}
            >
              Password
            </LabeledInput>
          )}

          {authType == RESET_PASS ? (
            <div />
          ) : (
            <UnderlinedLinkButton
              style={{
                marginTop: 20,
                color: "white",
                textDecorationColor: "white",
              }}
              onClick={() => {
                setAuthType(RESET_PASS);
              }}
            >
              Forgot password
            </UnderlinedLinkButton>
          )}
        </div>
      </Row>
      <Row style={{ paddingBottom: 100 }}>
        <div>
          <Column
            style={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Row style={{ alignItems: "center", marginTop: 10 }}>
              {authType != LOGIN && (
                <SecondaryButton
                  onClick={() => {
                    switch (authType) {
                      case LOGIN:
                        //  setAuthType(SIGN_UP);
                        break;
                      //  case SIGN_UP:
                      case RESET_PASS:
                        setAuthType(LOGIN);
                        break;
                    }
                  }}
                  style={{ marginBottom: 0 }}
                >
                  {authType == RESET_PASS
                    ? "Cancel"
                    : authType == LOGIN
                    ? "Sign up"
                    : "Login"}
                </SecondaryButton>
              )}

              {authType == RESET_PASS ? (
                <PrimaryButton
                  style={{ marginBottom: 0, marginRight: 0 }}
                  onClick={submit}
                >
                  {"Send Reset Email"}
                </PrimaryButton>
              ) : (
                <WideButton
                  style={
                    isMobile
                      ? { marginTop: 70, width: "70vw" }
                      : { marginTop: 70 }
                  }
                  onClick={submit}
                >
                  {authType == LOGIN ? "SIGN IN" : "Sign up"}
                </WideButton>
              )}
            </Row>
            {authType == RESET_PASS ? (
              <div />
            ) : (
              <UnderlinedLinkButton
                style={{
                  marginTop: 20,
                  color: "white",
                  fontSize: 18,
                  fontWeight: 800,
                  textDecorationColor: "white",
                }}
                onClick={() => {
                  navigate("/apps");
                }}
              >
                Or, do more on the app
              </UnderlinedLinkButton>
            )}
          </Column>
        </div>
      </Row>
    </div>
  );
}

export default Login;
