import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import { IoMdClose } from "react-icons/io";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Player } from "video-react";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
import { Column } from "../../../components/general";
import { BoldText } from "../../../components/text";
import { Colors } from "../../../constants/colors";
import { CollabMessage } from "../../../models/collabMessage";
import { LargeAudioPlayer } from "./message-item-detail";

function CollabItemDetail() {
  const { collabId, messageId } = useParams();
  let navigate = useNavigate();
  const [file, setFile] = useState<CollabMessage>();
  let [searchParams, setSearchParams] = useSearchParams();

  const roomName = useMemo(() => {
    if (searchParams) {
      return searchParams.get("roomName") ?? "";
    }
    return "";
  }, [searchParams]);

  useEffect(() => {
    fetchFile();
  }, [collabId, messageId]);

  const fetchFile = async () => {
    if (!messageId) return;
    let ref = doc(getFirestore(), "collaborationMessages", messageId);
    let snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      setFile({ ...snapshot.data(), id: messageId } as CollabMessage);
    }
  };

  const name = useMemo(() => {
    if (file) {
      if (file.audioTitle) {
        return file.audioTitle;
      }
      let base = file.image ?? "";
      if (file.video) {
        base = file.video;
      }
      if (base.split("%2F").length > 1) {
        let newBase = base.split("%2F")[1];
        return newBase.split("?")[0];
      }
      return file.kind;
    }
    return "";
  }, [file]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <div>
        <IoMdClose
          style={{
            color: Colors.Blue,
            fontSize: 34,
            padding: "3vh 2vw 1vh 2vw",

            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        />
      </div>

      <Column style={{ flex: 1, padding: "1vh 2vw" }}>
        <div>
          <BoldText
            style={{ fontSize: 18 }}
          >{`Rooms > ${roomName} > ${name}`}</BoldText>
        </div>
        {file && file.image && (
          <div style={{ marginLeft: "3vw", marginTop: "2vh" }}>
            <img
              style={{
                width: "90vw",

                height: "80vh",
                objectFit: "contain",
              }}
              src={file.image}
            ></img>
          </div>
        )}
        {file && file.video && (
          <div style={{ marginLeft: "35vw", marginTop: "2vh", width: "25vw" }}>
            <Player playsInline src={file.video} />
          </div>
        )}
        {file && file.audio && (
          <div>
            {file.audioTitle && (
              <BoldText style={{ fontSize: 18 }}>{file.audioTitle}</BoldText>
            )}
            <div style={{ marginLeft: "8vw", marginTop: "2vh", width: "80vw" }}>
              <LargeAudioPlayer
                audioUrl={file.audio}
                audioImage={file.audioImage}
              />
            </div>
          </div>
        )}
      </Column>
    </div>
  );
}

export default CollabItemDetail;
