import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { FaDownload } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Player } from "video-react";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
import { Column } from "../../../components/general";
import { BoldText } from "../../../components/text";
import { Colors } from "../../../constants/colors";
import { CollabMessage } from "../../../models/collabMessage";

function MessageItemDetail() {
  const { roomId, messageId } = useParams();
  let navigate = useNavigate();
  const [file, setFile] = useState<CollabMessage>();
  let [searchParams, setSearchParams] = useSearchParams();

  const roomName = useMemo(() => {
    if (searchParams) {
      return searchParams.get("roomName") ?? "";
    }
    return "";
  }, [searchParams]);

  useEffect(() => {
    fetchFile();
  }, [roomId, messageId]);

  const fetchFile = async () => {
    if (!roomId || !messageId) return;
    let ref = doc(getFirestore(), "rooms", roomId, "messages", messageId);
    let snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      setFile({ ...snapshot.data(), id: messageId } as CollabMessage);
    }
  };

  const name = useMemo(() => {
    if (file) {
      if (file.audioTitle) {
        return file.audioTitle;
      }
      let base = file.image ?? "";
      if (file.video) {
        base = file.video;
      }
      if (base.split("%2F").length > 1) {
        let newBase = base.split("%2F")[1];
        return newBase.split("?")[0];
      }
      return file.kind;
    }
    return "";
  }, [file]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <div>
        <IoMdClose
          style={{
            color: Colors.Blue,
            fontSize: 34,
            padding: "3vh 2vw 1vh 2vw",

            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        />
      </div>

      <Column style={{ flex: 1, padding: "1vh 2vw" }}>
        <div>
          <BoldText
            style={{ fontSize: 18 }}
          >{`Rooms > ${roomName} > ${name}`}</BoldText>
        </div>
        {file && file.image && (
          <div style={{ marginLeft: "3vw", marginTop: "2vh" }}>
            <img
              style={{
                width: "90vw",

                height: "80vh",
                objectFit: "contain",
              }}
              src={file.image}
            ></img>
          </div>
        )}
        {file && file.video && (
          <div style={{ marginLeft: "35vw", marginTop: "2vh", width: "25vw" }}>
            <Player playsInline src={file.video} />
          </div>
        )}
        {file && file.audio && (
          <div>
            {file.audioTitle && (
              <BoldText style={{ fontSize: 18 }}>{file.audioTitle}</BoldText>
            )}
            <div style={{ marginLeft: "8vw", marginTop: "2vh", width: "80vw" }}>
              <LargeAudioPlayer
                audioUrl={file.audio}
                audioImage={file.audioImage}
              />
            </div>
          </div>
        )}
      </Column>
    </div>
  );
}

export default MessageItemDetail;

export const LargeAudioPlayer = ({
  audioUrl,
  audioImage,
}: {
  audioUrl: string;
  audioImage?: string;
}) => {
  return (
    <div
      style={{
        padding: 6,
      }}
    >
      <AudioPlayer
        style={{
          backgroundColor: "transparent",
          color: "white",
          fontFamily: "Helvetica",
        }}
        header={
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginBottom: "2vh",
            }}
          >
            <div
              style={{
                width: "50vw",

                height: "70vh",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",

                backgroundImage: `url(${
                  audioImage
                    ? audioImage
                    : require("../../../assets/login-background.png")
                })`,
              }}
            >
              {!audioImage && (
                <img
                  src={require("../../../assets/icon-white.png")}
                  style={{ width: 180 }}
                ></img>
              )}
            </div>
          </div>
        }
        showFilledVolume={false}
        customVolumeControls={[]}
        customProgressBarSection={[
          RHAP_UI.MAIN_CONTROLS,
          RHAP_UI.CURRENT_TIME,
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.DURATION,
          RHAP_UI.ADDITIONAL_CONTROLS,
        ]}
        customControlsSection={[]}
        customAdditionalControls={[
          <a style={{ cursor: "pointer", color: "white" }} href={audioUrl}>
            <FaDownload style={{ fontSize: 18 }} />
          </a>,
        ]}
        autoPlay={false}
        src={audioUrl}
        onPlay={(e) => console.log("onPlay")}
        showJumpControls={false}
        showSkipControls={false}
      />
    </div>
  );
};
