import { useMemo, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { Row } from "../../../components/general";
import { BoldText } from "../../../components/text";
import { useMyCollaborations } from "../../../hooks/useCollaborations";
import { useMe } from "../../../hooks/useMe";
import { useMyRooms } from "../../../hooks/useMyRooms";
import CollabItem from "./collab-item";
import RoomItem from "./room-item";

export default function MessagesList() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const me = useMe();
  const userId = me && me.id ? me.id : "-1";
  const roomThreads = useMyRooms(userId);
  const messageThreads = useMyCollaborations(userId);
  const [roomsOpen, setRoomsOpen] = useState(true);
  const [messagesOpen, setMessagesOpen] = useState(true);

  const roomData = useMemo(() => {
    let chats = roomThreads || [];
    chats.sort(function (a, b) {
      return b.lastupdate.seconds - a.lastupdate.seconds;
    });

    return chats.filter((item) => !item.archived);
  }, [roomThreads]);

  const messageData = useMemo(() => {
    let raiChat = (messageThreads || []).find((c) => c.userIds.includes("RAI"));
    let chats = (messageThreads || []).filter(
      (c) => !c.userIds.includes("RAI")
    );
    chats.sort(function (a, b) {
      return b.lastupdate.seconds - a.lastupdate.seconds;
    });

    if (raiChat) {
      return [...chats, raiChat];
    }
    return chats;
  }, [messageThreads]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Row
        style={{
          padding: "2vh 2vw",
          alignItems: "center",
          cursor: "pointer",
          minWidth: isMobile ? "94vw" : 380,
        }}
        onClick={() => setMessagesOpen(!messagesOpen)}
      >
        <BoldText style={{ fontSize: 24 }}>MESSAGES</BoldText>
        {messagesOpen ? (
          <FaChevronDown style={{ marginLeft: 8, fontSize: 18 }} />
        ) : (
          <FaChevronRight style={{ marginLeft: 8, fontSize: 18 }} />
        )}
      </Row>

      {messagesOpen &&
        messageData.map((chat: any) => <CollabItem collab={chat} />)}

      <Row
        style={{
          padding: "2vh 2vw",
          alignItems: "center",
          cursor: "pointer",
          minWidth: isMobile ? "94vw" : 380,
        }}
        onClick={() => setRoomsOpen(!roomsOpen)}
      >
        <BoldText style={{ fontSize: 24 }}>ROOMS</BoldText>
        {roomsOpen ? (
          <FaChevronDown style={{ marginLeft: 8, fontSize: 18 }} />
        ) : (
          <FaChevronRight style={{ marginLeft: 8, fontSize: 18 }} />
        )}
      </Row>

      {roomsOpen && roomData.map((chat: any) => <RoomItem room={chat} />)}
    </div>
  );
}
