import * as linkify from "linkifyjs";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
import { BodyText, BoldText } from "../../../components/text";
import { CollabMessage } from "../../../models/collabMessage";
// @ts-ignore
import Linkify from "react-linkify";

export default function LinkList({
  filePosts,
}: {
  filePosts: CollabMessage[];
}) {
  return (
    <div
      style={{
        marginTop: 10,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        padding: "2vh 0 1vh 1vw",
        borderRadius: 8,
        marginLeft: "-1vw",
        width: "calc(440px - 3vw)",
      }}
    >
      <BoldText style={{ marginBottom: 12 }}>Links</BoldText>
      {filePosts.length == 0 && (
        <div
          style={{
            opacity: 0.7,
          }}
        >
          <BodyText>No links.</BodyText>
        </div>
      )}
      <div style={{ flexDirection: "row", flexWrap: "wrap", display: "flex" }}>
        {[...filePosts].map((item) => {
          let links = linkify.find(item.text);

          return [...links].map((link) => (
            <div
              style={{
                marginRight: 12,
                marginBottom: 12,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 8,
              }}
              key={item.id}
            >
              <Linkify>
                {/* tara here today link preview  */}
                <BodyText
                  style={{
                    width: "calc(440px - 5vw)",
                    overflow: "hidden",
                  }}
                >
                  {link.href}
                </BodyText>
              </Linkify>
            </div>
          ));
        })}
      </div>
    </div>
  );
}
