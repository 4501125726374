import { IoPerson } from "react-icons/io5";

export function AvatarItem({
  avatar,
  index,
  imageSize,
}: {
  avatar: string;
  index: number;
  imageSize: number;
}) {
  return avatar ? (
    <img
      src={avatar}
      style={{
        width: imageSize,
        height: imageSize,
        borderRadius: imageSize / 2,
        marginLeft: index > 0 ? -8 : 0,
      }}
    />
  ) : (
    <div
      style={{
        width: imageSize,
        height: imageSize,
        borderRadius: imageSize / 2,
        backgroundColor: "white",
        marginLeft: index > 0 ? -8 : 0,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <IoPerson style={{ color: "black", fontSize: imageSize * 0.7 }} />
    </div>
  );
}
