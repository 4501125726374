import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OutlineButton } from "../../components/buttons";
import EmptyAudioBackground from "../../components/empty-audio-background";
import {
  Column,
  FullHeightContainer,
  HundredPercentContainer,
  Row,
} from "../../components/general";
import { BoldText } from "../../components/text";
import { Colors } from "../../constants/colors";
import { useMe } from "../../hooks/useMe";
import { Post } from "../../models/post";
import Header from "../../navigation/header";
import FoldersList from "./folders-list";
import { Folder } from "../../models/folder";
import { useMediaQuery } from "react-responsive";

function FilesPaginator({
  selectedFolder,
  setSelectedFolder,
}: {
  selectedFolder: Folder;
  setSelectedFolder: any;
}) {
  const [files, setFiles] = useState<Post[]>([]);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const me = useMe();
  const userId = me && me.id ? me.id : "-1";
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [lastItem, setLastItem] = useState<any>();

  useEffect(() => {
    setLastItem(null);
    setCanLoadMore(true);
    setFiles([]);
    loadFiles(selectedFolder);
  }, [selectedFolder]);

  const loadFiles = async (selectedFolder: Folder) => {
    const coll = collection(getFirestore(), "posts");
    const generalQuery = query(
      coll,
      where("userId", "==", userId),
      where("archived", "==", false),
      where("kind", "in", ["audio", "video", "image"]),

      limit(20)
    );

    let snapshot = await getDocs(
      selectedFolder
        ? query(
            coll,
            where("parentFolderIds", "array-contains", selectedFolder.id),
            limit(20)
          )
        : generalQuery
    );
    let posts: Post[] = [];
    var index = 0;
    snapshot.forEach((doc) => {
      posts.push({ ...doc.data(), id: doc.id } as Post);
      if (index == snapshot.docs.length - 1) {
        setLastItem(doc);
      }
      index++;
    });
    setFiles(posts);

    if (posts.length < 20) {
      setCanLoadMore(false);
    }
  };

  const loadMore = async () => {
    const coll = collection(getFirestore(), "posts");

    const generalQuery = query(
      coll,
      where("userId", "==", userId),
      where("archived", "==", false),
      where("kind", "in", ["audio", "video", "image"]),
      startAfter(lastItem),
      limit(20)
    );

    let snapshot = await getDocs(
      selectedFolder
        ? query(
            coll,
            where("parentFolderIds", "array-contains", selectedFolder.id),
            startAfter(lastItem),
            limit(20)
          )
        : generalQuery
    );

    let posts: Post[] = [];
    var index = 0;
    snapshot.forEach((doc) => {
      posts.push({ ...doc.data(), id: doc.id } as Post);
      if (index == snapshot.docs.length - 1) {
        setLastItem(doc);
      }
      index++;
    });
    setFiles([...files, ...posts]);

    if (posts.length < 20) {
      setCanLoadMore(false);
    }
  };
  return (
    <Column
      style={{
        flex: 1,
        display: "flex",
        minHeight: "calc(100vh - 160px)",
        borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
        paddingLeft: isMobile ? "2vh" : 0,
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: Colors.Background,
          zIndex: 2,
          position: "sticky",
          top: 160,
          padding: "2vh 2vw 0vh 2vw",
        }}
      >
        <BoldText style={{ marginBottom: 12 }}>
          {selectedFolder ? selectedFolder.name : "Recent Files"}
        </BoldText>
      </div>

      <div
        style={{
          width: "100%",
          overflowY: "scroll",
          backgroundColor: Colors.Background,
          position: "relative",
        }}
      >
        <Column style={{ flex: 1, padding: "2vh 2vw" }}>
          <div
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              display: "flex",
            }}
          >
            {[...files].map((item) => (
              <FileItem
                item={item}
                key={item.id}
                folderName={selectedFolder ? selectedFolder.name : "Files"}
              />
            ))}
          </div>
          {canLoadMore && lastItem && (
            <div
              style={{
                height: 40,
                width: "100%",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.7,
              }}
            >
              <OutlineButton onClick={loadMore}>Load More</OutlineButton>
            </div>
          )}
        </Column>
      </div>
    </Column>
  );
}

export default FilesPaginator;

function FileItem({ item, folderName }: { item: Post; folderName: string }) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const imageWidth = isMobile ? "38vw" : 240;
  const imageHeight = isMobile ? 60 : 140;
  let navigate = useNavigate();
  const name = useMemo(() => {
    if (item) {
      if (item.uploadTitle) {
        return item.uploadTitle;
      }
      let base = item.image ?? "";
      if (item.video) {
        base = item.video;
      }
      if (base.split("%2F").length > 1) {
        let newBase = base.split("%2F")[1];
        return newBase.split("?")[0];
      }
      return item.kind;
    }
    return "";
  }, [item]);

  return (
    <div
      style={{
        marginRight: 12,
        marginBottom: 12,
        backgroundColor: "rgba(255, 255, 255, 0.2)",

        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        padding: 7,
      }}
      key={item.id}
      onClick={() => {
        navigate(`/files/${item.id}?folderName=${folderName}`);
      }}
    >
      {item.image ? (
        <img
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            width: imageWidth,
            height: imageHeight,
            borderRadius: 8,
            objectFit: "cover",
          }}
          src={item.image}
        />
      ) : (
        <EmptyAudioBackground
          size={imageWidth}
          height={imageHeight}
          iconSize={isMobile ? "6vh" : null}
        />
      )}

      <div style={{ width: imageWidth, paddingTop: 6 }}>
        <BoldText style={{ fontSize: 14 }}>{name}</BoldText>
      </div>
    </div>
  );
}
